import { forwardRef, useImperativeHandle } from 'react';
import { surveyTemplateOptions } from "@/utils/constant";
import { Button, Form, Input, Select } from "antd";
import { Row } from "antd";
import { Col } from "antd";
import { useForm } from "antd/lib/form/Form";


interface Props {
  onCreate: (values: any) => void;
  loading: boolean;
}

export interface SurveyConfigurationRef {
  resetFields: () => void;
}

const SurveyConfiguration = forwardRef<SurveyConfigurationRef, Props>((props, ref) => {
  const [form] = useForm();
  const onFinish = (values: any) => {
    values.state = "open";
    props.onCreate(values);
  };

  useImperativeHandle(ref, () => ({
    resetFields
  }));

  const resetFields = () => {
    form.resetFields();
  };

  return (
    <div className="survey-configuration">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col md={12} xs={24}>
            <Form.Item label="问卷名称" name="title" rules={[{ required: true, message: "" }]}>
              <Input placeholder="请输入" />
            </Form.Item>
          </Col>
          <Col md={6} xs={24}>
            <Form.Item label="问卷模板" name="temKey" rules={[{ required: true, message: "" }]}>
              <Select placeholder="请选择" options={surveyTemplateOptions} />
            </Form.Item>
          </Col>
          <Col md={6} xs={24} className="hdp-uf hdp-uf-vfe hdp-uf-hfe" style={{ marginLeft: "auto" }}>
            <Form.Item shouldUpdate>
              {() => (
                <Button 
                  type="primary" 
                  htmlType="submit"
                  loading={props.loading}
                >
                  保存
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
});

export default SurveyConfiguration;