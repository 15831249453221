import { deviceWidth, downloadFile, filterColumnsData } from "@/utils";
import { Button, message, Table } from "antd";
import { VerticalAlignBottomOutlined, FileProtectOutlined, ProfileFilled } from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import UploadModal from "../uploadModal";
import ContractDetailModal from "../contractDetailModal";
import { downloadContractDetail, uploadChop } from "@/api/contractReport";
import moment from "moment";

type TableType = "processing" | "complete";
interface Props {
  type: TableType;
  fieldsDesc: any;
  tableData: any;
  pagination: any;
  loading: boolean;
  refreshList?: () => void;
  rowSelection?: any;
}

const TableList: React.FC<Props> = ({ type, fieldsDesc, tableData, pagination, loading, refreshList, rowSelection }) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [isContractDetailModalVisible, setIsContractDetailModalVisible] = useState(false);
  const [currentActionRecord, setCurrentActionRecord] = useState<any>(null);
  const [downloadingId, setDownloadingId] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  /**
   * 将文件转换为 Base64 格式
   * @param {File} file - 要转换的文件
   * @returns {Promise<string | ArrayBuffer | null>} - 返回文件的 Base64 编码
   */
  const fileToBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  /**
   * 处理文件上传
   * @param {{ files: File[] }} param0 - 包含要上传的文件的对象
   */
  const handleUpload = useCallback(
    async ({ files }: { files: File[] }) => {
      if (!currentActionRecord || !files?.length) {
        message.warn("没有可上传的文件或未选择记录");
        return;
      }
      setUploading(true);
      try {
        const file = files[0];
        const base64FileContent = await fileToBase64(file);
        // 移除 MIME 类型前缀
        const base64String = (base64FileContent as string).split(',')[1];

        const uploadParams = {
          fileName: file.name,
          fileContent: base64String,
          requestId: currentActionRecord.requestNo,
        };

        await uploadChop(uploadParams);
        message.success("上传成功");
        if (refreshList) {
          refreshList();
        }
      } catch (error: any) {
        console.error("文件上传失败:", error);
        message.error(error?.message || "文件上传失败，请重试");
      } finally {
        setUploading(false);
      }
    },
    [currentActionRecord, refreshList]
  );

  /**
   * 处理文件下载
   * @param {any} record - 包含下载信息的记录
   */
  const handleDownload = useCallback(
    async (record: any) => {
      setDownloadingId(record.requestNo);
      try {
        // 检查文件名是否有有效的后缀
        let fileName = record.fileName;
        const fileExtRegex = /\.[0-9a-z]+$/i;
        if (!fileExtRegex.test(fileName)) {
          fileName += '.pdf'; // 如果没有有效后缀，添加 .pdf
        }

        const res = await downloadContractDetail([
          {
            fileKey: record.fileKey,
            fileName: fileName,
          }
        ]);
        downloadFile(res, fileName);
        message.success("下载成功");
      } catch (error: any) {
        console.error("文件下载失败:", error);
        message.error(error?.message || "文件下载失败，请重试");
      } finally {
        setDownloadingId(null);
      }
    },
    []
  );

  const allColumns = useMemo(
    () =>
      [
        { title: "签章编号", dataIndex: "requestNo", key: "requestNo" },
        { title: "文件名", dataIndex: "fileName", key: "fileName" },
        { title: "文件类型  ", dataIndex: "fileType", key: "fileType" },
        { title: "卖方", dataIndex: "orgName", key: "orgName" },
        { title: "买方", dataIndex: "customerName", key: "customerName" },
        { title: "签章状态", dataIndex: "chopStatus", key: "chopStatus" },
        {
          title: "签章创建时间", dataIndex: "createTime", key: "createTime",
          render: (_: any, record: any) => record.createTime ? moment(record.createTime).format("YYYY-MM-DD HH:mm:ss") : ""
        },
        { title: "合同编号", dataIndex: "contractNo", key: "contractNo" },
        { title: "提货开始日期", dataIndex: "executionStartDate", key: "executionStartDate" },
        { title: "提货截至日期", dataIndex: "executionEndDate", key: "executionEndDate" },
        { title: "物料编码", dataIndex: "materialNo", key: "materialNo" },
        { title: "物料名称", dataIndex: "materialName", key: "materialName" },
        { title: "合同数量", dataIndex: "batchVolume", key: "batchVolume" },
        { title: "结算单价", dataIndex: "price", key: "price", render: (_: any, record: any) => record.price || "-" },
        { title: "基差价格", dataIndex: "basis", key: "basis", render: (_: any, record: any) => record.basis || "-" },
        { title: "交提货方式", dataIndex: "shipmentMethod", key: "shipmentMethod" },
        { title: "交提货地点", dataIndex: "incotermsLocationName", key: "incotermsLocationName" },
        { title: "业务线", dataIndex: "businessLine", key: "businessLine" },
        { title: "合同类型", dataIndex: "contractType", key: "contractType" },
        { title: "合同签订日期", dataIndex: "contractDate", key: "contractDate", render: (_: any, record: any) => record.contractDate || "-" },
        { title: "合同点价日期", dataIndex: "priceDate", key: "priceDate", render: (_: any, record: any) => record.priceDate || "-" },
        { title: "期货合约月", dataIndex: "dceZce", key: "dceZce", render: (_: any, record: any) => record.dceZce || "-" }
      ],
    []
  );

  /**
   * 根据数据设置表格列
   */
  const setColumnByData = useCallback(
    () => {
      const filteredColumns = filterColumnsData(allColumns, fieldsDesc);
      filteredColumns.push(
        {
          title: "合同详情",
          key: "detail",
          align: "center",
          fixed: deviceWidth() > 576 ? ("right" as any) : false,
          render: (record: any) => (
            <Button
              type="link"
              icon={<ProfileFilled />}
              onClick={() => {
                setCurrentActionRecord(record);
                setIsContractDetailModalVisible(true);
              }}
              disabled={record.contractNo}
            />
          ),
        },
        {
          title: "合同下载",
          key: "download",
          align: "center",
          fixed: deviceWidth() > 576 ? ("right" as any) : false,
          render: (record: any) => (
            <Button
              type="link"
              icon={<VerticalAlignBottomOutlined />}
              loading={downloadingId === record.requestNo}
              onClick={() => handleDownload(record)}
              disabled={!record.fileKey}
            />
          ),
        }
      );

      if (type !== 'complete') {
        filteredColumns.push({
          title: "签章上传",
          key: "upload",
          align: "center",
          fixed: deviceWidth() > 576 ? ("right" as any) : false,
          render: (record: any) => (
            <Button
              type="link"
              icon={<FileProtectOutlined />}
              onClick={() => {
                setCurrentActionRecord(record);
                setIsUploadModalVisible(true);
              }}
            />
          ),
        });
      }
      setColumns(filteredColumns);
    },
    [fieldsDesc, allColumns, downloadingId, handleDownload, type]
  );

  useEffect(() => {
    setColumnByData();
  }, [setColumnByData]);

  return (
    <div className="table-list">
      <Table
        rowSelection={rowSelection}
        columns={columns}
        pagination={pagination}
        scroll={{ x: "max-content" }}
        dataSource={tableData}
        rowKey="requestNo"
        loading={loading}
        size="small"
      />
      <UploadModal
        visible={isUploadModalVisible}
        onClose={() => {
          setIsUploadModalVisible(false);
          setCurrentActionRecord(null);
        }}
        onUpload={handleUpload}
        loading={uploading}
      />
      <ContractDetailModal
        visible={isContractDetailModalVisible}
        requestNo={currentActionRecord?.requestNo}
        onClose={() => {
          setIsContractDetailModalVisible(false);
          setCurrentActionRecord(null);
        }}
      />
    </div>
  );
};

export default TableList;
