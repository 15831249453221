import { getLocalStorageItem } from "./myLocalStorage";

// 外部用户选则全部时，orgCode(卖方code)和divisionCode(业务线code)的值
const allOrgCode = "1142,1006,1140,1254,1721,1959";
const allDivisionCode = "39,38,43,40";
const allBusinessLines = "包装油业务,基础精炼业务,增值油业务,谷物油籽业务";

const supplierList = [
  { value: "1142", label: "嘉吉粮油（阳江）有限公司" },
  { value: "1006", label: "嘉吉粮油（南通）有限公司" },
  { value: "1140", label: "东莞嘉吉粮油有限公司" },
  { value: "1254", label: "河北嘉好粮油有限公司" },
  { value: "1721", label: "东莞嘉吉饲料蛋白科技有限公司" },
  { value: "1959", label: "嘉吉粮油（日照）有限公司" }
];

const businessLineList = [
  { value: "38", label: "基础精炼业务" },
  { value: "43", label: "增值油业务" },
  { value: "40", label: "谷物油籽业务" },
  { value: "39", label: "包装油业务" }
];

// 如果该用户在user-management创建时已经设置了限制卖方
const supplierOptionsFilter = (dataSource: any) => {
  let newData = [...dataSource];
  const localUserDetailsData = getLocalStorageItem("localUserDetailsData");
  if (localUserDetailsData) {
    const localUserDetailsDataObj = JSON.parse(localUserDetailsData);
    if (localUserDetailsDataObj.entitlements?.length > 0 && localUserDetailsDataObj.entitlements[0].plant) {
      const supplierItem = supplierList.filter(
        (item: any) => item.value === localUserDetailsDataObj.entitlements[0].plant
      );
      if (supplierItem && supplierItem.length > 0) {
        newData = newData.filter((item: any) => item.label === supplierItem[0].label);
      }
    }
  }
  return newData;
};

// 如果该用户在user-management创建时已经设置了限制的业务线
const businessLineOptionsFilter = (dataSource: any) => {
  let newData = [...dataSource];
  const localUserDetailsData = getLocalStorageItem("localUserDetailsData");
  if (localUserDetailsData) {
    const localUserDetailsDataObj = JSON.parse(localUserDetailsData);
    if (localUserDetailsDataObj.entitlements?.length > 0 && localUserDetailsDataObj.entitlements[0].businessLine) {
      const businessLineItem = businessLineList.filter(
        (item: any) => item.value === localUserDetailsDataObj.entitlements[0].businessLine
      );
      if (businessLineItem && businessLineItem.length > 0) {
        newData = newData.filter((item: any) => item.label === businessLineItem[0].label);
      }
    }
  }
  return newData;
};

// 全部卖方--1--OK
const allSupplier = (ignoreFields?: string[]) => {
  const options = [
    { value: "", label: "请选择卖方", disabled: true },
    { value: "All", label: "全部卖方" },
    { value: "1142", label: "嘉吉粮油（阳江）有限公司" },
    { value: "1006", label: "嘉吉粮油（南通）有限公司" },
    { value: "1140", label: "东莞嘉吉粮油有限公司" },
    { value: "1254", label: "河北嘉好粮油有限公司" },
    { value: "1721", label: "东莞嘉吉饲料蛋白科技有限公司" },
    { value: "1959", label: "嘉吉粮油（日照）有限公司" }
  ];
  if (ignoreFields && ignoreFields.length > 0) {
    return supplierOptionsFilter(options.filter(item => !ignoreFields.includes(item.value)));
  }
  return supplierOptionsFilter(options);
};

// 全部卖方--2--OK
const allSupplierCN = () => {
  const options = [
    { value: "", label: "请选择卖方", disabled: true },
    { value: "All", label: "全部卖方" },
    { value: "嘉吉粮油（日照）有限公司", label: "嘉吉粮油（日照）有限公司" },
    { value: "嘉吉粮油（阳江）有限公司", label: "嘉吉粮油（阳江）有限公司" },
    { value: "嘉吉粮油（南通）有限公司", label: "嘉吉粮油（南通）有限公司" },
    { value: "东莞嘉吉粮油有限公司", label: "东莞嘉吉粮油有限公司" },
    { value: "河北嘉好粮油有限公司", label: "河北嘉好粮油有限公司" },
    { value: "东莞嘉吉饲料蛋白科技有限公司", label: "东莞嘉吉饲料蛋白科技有限公司" }
  ];
  return supplierOptionsFilter(options);
};

// 全部卖方--3--OK
const allSupplierMutiple = (ignoreFields?: string[]) => {
  const options = [
    { value: "", label: "请选择卖方", disabled: true },
    { value: "1142", label: "嘉吉粮油（阳江）有限公司" },
    { value: "1006", label: "嘉吉粮油（南通）有限公司" },
    { value: "1140", label: "东莞嘉吉粮油有限公司" },
    { value: "1254", label: "河北嘉好粮油有限公司" },
    { value: "1721", label: "东莞嘉吉饲料蛋白科技有限公司" },
    { value: "1959", label: "嘉吉粮油（日照）有限公司" }
  ];
  if (ignoreFields && ignoreFields.length > 0) {
    return supplierOptionsFilter(options.filter(item => !ignoreFields.includes(item.value)));
  }
  // return supplierOptionsFilter(options);
  return options;
};

// 全部业务线--1--OK
const allBusinessLine = (ignoreFields?: string[]) => {
  const options = [
    { value: "", label: "请选择业务线", disabled: true },
    { value: "All", label: "全部业务" },
    { value: "38", label: "基础精炼业务" },
    { value: "43", label: "增值油业务" },
    { value: "40", label: "谷物油籽业务" },
    { value: "39", label: "包装油业务" }
  ];
  if (ignoreFields && ignoreFields.length > 0) {
    return businessLineOptionsFilter(options.filter(item => !ignoreFields.includes(item.value)));
  }
  return businessLineOptionsFilter(options);
};

// 全部业务线--1--OK
const allBusinessLineMutiple = (ignoreFields?: string[]) => {
  const options = [
    { value: "38", label: "基础精炼业务" },
    { value: "43", label: "增值油业务" },
    { value: "40", label: "谷物油籽业务" },
    { value: "39", label: "包装油业务" }
  ];
  if (ignoreFields && ignoreFields.length > 0) {
    return businessLineOptionsFilter(options.filter(item => !ignoreFields.includes(item.value)));
  }
  return businessLineOptionsFilter(options);
};

// 全部业务线--2--OK
const allBusinessLineCN = (ignoreFields?: string[]) => {
  const options = [
    { value: "", label: "请选择业务线", disabled: true },
    { value: "All", label: "全部业务" },
    { value: "基础精炼业务", label: "基础精炼业务" },
    { value: "增值油业务", label: "增值油业务" },
    { value: "谷物油籽业务", label: "谷物油籽业务" },
    { value: "包装油业务", label: "包装油业务" }
  ];
  if (ignoreFields && ignoreFields.length > 0) {
    return businessLineOptionsFilter(options.filter(item => !ignoreFields.includes(item.value)));
  }
  return businessLineOptionsFilter(options);
};

const allOccBusinessLine = (ignoreFields?: string[]) => {
  const options = [
    { value: "", label: "请选择业务线", disabled: true },
    { value: "基础精炼业务", label: "基础精炼业务" },
    { value: "谷物油籽业务", label: "谷物油籽业务" },
    { value: "增值油/包装油业务", label: "增值油/包装油业务" }
  ];
  if (ignoreFields && ignoreFields.length > 0) {
    return businessLineOptionsFilter(options.filter(item => !ignoreFields.includes(item.value)));
  }
  return businessLineOptionsFilter(options);
};

// 全部发票业务
const allInvoiceBusinessOptions = [
  { value: "", label: "全部发票业务" },
  { value: "Trading", label: "Trading" },
  { value: "Crush", label: "Crush" },
  { value: "VA", label: "VA" },
  { value: "BR", label: "BR" },
  { value: "BRMP", label: "BRMP" }
];

// 全部的预约状态
const allBookingStatus = [
  { label: "全部状态", value: "" },
  { label: "待预约", value: "waiting" },
  { label: "已预约", value: "success" },
  { label: "放弃预约", value: "FAIL_OPENCARD" },
  { label: "预约已执行", value: "SUC_OPRNCARD" },
  { label: "VIP提货", value: "VIP" }
];

const allCloseStatus = [
  { value: "Y", label: "是" },
  { value: "N", label: "否" }
];

const allCustomerPricingStatus = [
  { value: "", label: "请选择结价状态", disabled: true },
  { value: "ALL", label: "所有" },
  { value: "已结价", label: "已结价" },
  { value: "未结价", label: "未结价" }
];

// 提单申请状态
export const doStatus = [
  { value: "", label: "请选择提单申请状态", disabled: true },
  { value: "所有", label: "所有状态" },
  { value: "受理中", label: "受理中" },
  { value: "系统审核中", label: "系统审核中" },
  { value: "已开单", label: "已开单" },
  { value: "信用审核失败", label: "信用审核失败" },
  { value: "已拒绝", label: "已拒绝" },
  { value: "待取消", label: "待取消" },
  { value: "已取消", label: "已取消" },
  { value: "限量审核失败", label: "限量审核失败" }
];

// 提单申请状态
export const pendingDOStatus = [
  { value: "", label: "请选择提单申请状态", disabled: true },
  { value: "所有", label: "所有状态" },
  { value: "受理中", label: "受理中" },
  { value: "系统审核中", label: "系统审核中" },
  { value: "待取消", label: "待取消" }
];

// 提单申请状态
export const doApplyStatus = [
  { value: "", label: "所有状态" },
  { value: "受理中", label: "受理中" },
  { value: "系统审核中", label: "系统审核中" },
  { value: "已开单", label: "已开单" },
  { value: "已拒绝", label: "已拒绝" },
  { value: "待取消", label: "待取消" },
  { value: "已取消", label: "已取消" }
];

// 合同类型
const contractType = [
  { value: "一口价合同", label: "一口价合同" },
  { value: "固定价合同", label: "固定价合同" },
  { value: "CRM已结价合同", label: "CRM已结价合同" },
  { value: "CRM未结价合同", label: "CRM未结价合同" },
  { value: "暂定价合同", label: "暂定价合同" },
  { value: "基差已结价合同", label: "基差已结价合同" },
  { value: "基差未结价合同", label: "基差未结价合同" }
];

// 合同类型
const customerContractType = [
  { value: "固定价合同", label: "固定价合同" },
  { value: "基差价合同", label: "基差价合同" },
  { value: "暂定价合同", label: "暂定价合同" },
  { value: "CRM合同", label: "CRM合同" },
  { value: "一口价合同", label: "一口价合同" }
];

// 合同类型
const oldContractType = [
  { value: "固定价合同", label: "固定价合同" },
  { value: "基差已结价合同", label: "基差已结价合同" },
  { value: "基差未结价合同", label: "基差未结价合同" },
  { value: "暂定价合同", label: "暂定价合同" }
];

//预约配置类型
const bookingConfigType = [
  { value: "", label: "请选择配置类型", disabled: true },
  { value: "ban", label: "工厂休息时间" },
  { value: "transport", label: "内部预留时间" }
];

//返回工厂代码
const returnSiteCode = [
  { value: "", label: "请选择卖方工厂", disabled: true },
  { value: "RZH", label: "嘉吉粮油（日照）有限公司", orgCode: "1959" },
  { value: "YAN", label: "嘉吉粮油（阳江）有限公司", orgCode: "1142" },
  { value: "NAN", label: "嘉吉粮油（南通）有限公司", orgCode: "1006" },
  { value: "MCN", label: "东莞嘉吉粮油有限公司", orgCode: "1140" },
  { value: "HUA", label: "河北嘉好粮油有限公司", orgCode: "1254" },
  { value: "MCS", label: "东莞嘉吉饲料蛋白科技有限公司", orgCode: "1721" }
];

//配置发货平台
const loadingRule = [
  { value: "", label: "请选择发货平台", disabled: true },
  { value: "BR", label: "BR散油发货平台" },
  { value: "VA", label: "VA包装油发货平台" },
  { value: "MG", label: "粕类发货平台" }
];

const tipsInfoData = [
  { num: 1, value: "嘉吉根据工厂情况每天更新或调整未来24~72小时的可预约提货时间段；" },
  { num: 2, value: "车辆预约基于已开具提单，未开单的车辆不可预约；" },
  {
    num: 3,
    value:
      "所有预约的车辆请于预约时间前2小时~前0.5小时之间开卡（例如，预约时间9:00，预约有效开卡时间为7:00~8:30），车辆提前或延时到达开卡，将会自动取消预约，进入正常排队通道提货；"
  },
  { num: 4, value: "未开卡前可以按系统功能进行变更或取消操作，开卡后则不可调整；" },
  { num: 5, value: "开卡后请关注工厂呼叫信息，及时入厂，超时将取消预约并重新进入排队通道提货；" },
  {
    num: 6,
    value:
      "如不能在预约时间前到达，请及时变更或取消，连续3次未在预约时间到达的车辆将会自动进入黑名单，15天内将不能预约；"
  },
  { num: 7, value: "如有特殊的异常情况，将可能整体推迟或取消预约，解释权归嘉吉所有，敬请关注信息通知。" }
];

const efficiencyOptions = [
  { label: "30", value: 30 },
  { label: "45", value: 45 },
  { label: "60", value: 60 },
  { label: "90", value: 90 },
  { label: "120", value: 120 },
  { label: "180", value: 180 }
];

const kaClientOptions = [
  { value: "WS", label: "温氏" },
  { value: "TW", label: "通威" },
  { value: "MY", label: "牧原" }
];

// 付款方式
export const payMethods = [
  { value: "", label: "请选择", disabled: true },
  { value: "All", label: "全部" },
  { value: "Cash", label: "现金" },
  { value: "Credit", label: "信用额度" }
];

// 物流状态-Sap
export const logisticsStatus = [
  { value: "", label: "请选择物流状态", disabled: true },
  { value: "所有", label: "所有" },
  { value: "已开单未开卡", label: "已开单未开卡" },
  { value: "已开卡", label: "已开卡" },
  { value: "装货中", label: "装货中" },
  { value: "已离厂", label: "已离厂" },
  { value: "已发货", label: "已发货" },
  { value: "已取消", label: "已取消" },
  { value: "已分组未开卡", label: "已分组未开卡" },
  { value: "外库/其他提单", label: "外库/其他提单" }
];

// 物流状态-CTS
export const logisticsStatus_CTS = [
  { value: "", label: "请选择物流状态", disabled: true },
  { value: "所有", label: "所有" },
  { value: "已开单未开卡", label: "已开单未开卡" },
  { value: "已开卡", label: "已开卡" },
  { value: "装货中", label: "装货中" },
  { value: "已离厂", label: "已离厂" },
  { value: "已取消", label: "已取消" },
  { value: "已分组未开卡", label: "已分组未开卡" },
  { value: "外库/自提船提单", label: "外库/自提船提单" }
];

// 是否拼单
export const isCombineOptions = [
  { value: "", label: "请选择", disabled: true },
  { value: "All", label: "全部" },
  { value: "true", label: "是" },
  { value: "false", label: "否" }
];

// 提货方式
export const pickUpMethodOptionsEn = [
  { value: "", label: "请选择", disabled: true },
  { label: "全部", value: "All" },
  { label: "自提", value: "ZTNULL" },
  { label: "配送", value: "DELIVERY" }
];

// 提货方式
export const pickUpMethodOptions = [
  { value: "", label: "请选择", disabled: true },
  { label: "全部", value: "All" },
  { label: "自提", value: "自提" },
  { label: "配送", value: "配送" }
];

// 是否录入配送信息
export const shippingInfoOption = [
  { value: "All", label: "全部" },
  { value: "true", label: "已录入" },
  { value: "false", label: "未录入" }
];

export const contractState = [
  { value: "", label: "全部" },
  { value: "01", label: "待创建" },
  { value: "02", label: "已创建" },
  { value: "06", label: "变更中" },
  { value: "07", label: "已变更" },
  { value: "05", label: "终止中" },
  { value: "04", label: "已终止" },
  { value: "03", label: "已拒绝" },
  { value: "08", label: "新建被拒绝" }
];

export const executeWay = [
  { value: "", label: "全部" },
  { value: "01", label: "新增" },
  { value: "02", label: "变更" },
  { value: "03", label: "终止" },
  { value: "04", label: "点价" },
  { value: "05", label: "转合约" }
];

export const systemSource = [
  { value: "", label: "全部来源" },
  { value: "TW", label: "通威合同" },
  { value: "WS", label: "温氏合同" },
  { value: "ZD", label: "正大合同" },
  { value: "MY", label: "牧原合同" }
];

export const remainedQtyLimit = [
  { value: "", label: "全部" },
  { value: "Y", label: "是" },
  { value: "N", label: "否" }
];

export const directConnectionApproval = [
  { value: "", label: "全部" },
  { value: "01", label: "通过" },
  { value: "02", label: "拒绝" },
  { value: "03", label: "等待审批" },
  { value: "04", label: "不需要审批" },
  { value: "05", label: "系统拒绝" }
];

export const businessScenario = [
  { value: "", label: "全部" },
  { value: "01", label: "一口价创建" },
  { value: "02", label: "基差创建" },
  { value: "03", label: "基差合同变更" },
  { value: "04", label: "定价方式变更" },
  { value: "05", label: "基差转合约" },
  { value: "06", label: "一口价变更" },
  { value: "07", label: "加权平均" },
  { value: "08", label: "基差点价" },
  { value: "09", label: "一口价终止" },
];

// 保证金类型
export const depositTypeOptions = [
  { value: "ALL", label: "全部保证金"},
  { value: "N", label: "履约保证金" },
  { value: "M", label: "追加保证金" },
  // { value: "F", label: "应收保证金" }
];

// 保证金状态
export const documentItemStatusOptions = [
  { value: "Received", label: "已支付" },
  { value: "Pending", label: "未支付" }
];

// 是否超期
export const dueOptions = [
  { value: "", label: "全部" },
  { value: "Y", label: "是" }
];

// 是否直连提单
export const b2bTagOptions = [
  { value: "", label: "全部" },
  { value: "true", label: "是" },
  { value: "false", label: "否" }
];

// 是否质量报告
export const qualityFileOptions = [
  { value: "", label: "全部" },
  { value: "true", label: "是" },
  { value: "false", label: "否" }
];

// 是否电子磅单
export const poundFileOptions = [
  { value: "", label: "全部" },
  { value: "true", label: "是" },
  { value: "false", label: "否" }
];
// 物流状态-CTS
export const billUsageStatusOptions = [
  { value: "ALL", label: "所有" },
  { value: "1", label: "已开单未发货"},
  { value: "2", label: "已发货未入账"}
];

export const typeOfShippingOptions = [
  { value: "", label: "全部" },
  { value: "车", label: "车" },
  { value: "船", label: "船" },
  { value: "火车", label: "火车" },
];

export const surveyTemplateOptions = [
  { value: "", label: "请选择问卷模板", disabled: true },
  { value: "豆粕调查表", label: "豆粕调查表" },
  { value: "油调查表-FS&BR", label: "油调查表-FS&BR" },
  { value: "油调查表-KA&FI", label: "油调查表-KA&FI" }
];

export const SITE_CODE_RZH = "嘉吉粮油（日照）有限公司";
export const SITE_CODE_YAN = "嘉吉粮油（阳江）有限公司";
export const SITE_CODE_NAN = "嘉吉粮油（南通）有限公司";
export const SITE_CODE_MCN = "东莞嘉吉粮油有限公司";
export const SITE_CODE_HUA = "河北嘉好粮油有限公司";
export const SITE_CODE_MCS = "东莞嘉吉饲料蛋白科技有限公司";

export const COMMODITY_CODE_BR = "基础精炼业务";
export const COMMODITY_CODE_VA = "增值油业务";
export const COMMODITY_CODE_MG = "谷物油籽业务";

export {
  supplierList,
  businessLineList,
  allSupplier,
  allSupplierCN,
  allSupplierMutiple,
  allBusinessLine,
  allBusinessLineCN,
  allBusinessLineMutiple,
  allInvoiceBusinessOptions,
  allOccBusinessLine,
  allBookingStatus,
  allCloseStatus,
  allCustomerPricingStatus,
  contractType,
  customerContractType,
  tipsInfoData,
  efficiencyOptions,
  allOrgCode,
  allDivisionCode,
  oldContractType,
  allBusinessLines,
  bookingConfigType,
  returnSiteCode,
  loadingRule,
  kaClientOptions
};
