import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Card, message, Modal, PageHeader, Table, Spin } from "antd";
import { getSavedDeliveryOrders, updateDos } from "@/api/deliveryOrder";
import CancelCombine from "@/pages/common/cancelCombine";
import CombineButton from "@/pages/common/combineButton";
import TableDelete from "@/pages/deliveryOrder/components/TableDelete";
import { extractNumber, isMobileDevice } from "@/utils/common";
import { convertUTCDateToChinaDate, cutBatchNoZero, setShipAddressDesc } from "@/utils/formatUtils";
import NotificationTips from "@/pages/common/notificationTips";

import "./index.scss";

interface Props {
  permissions: any;
  switcher: any;
}

interface State {
  data: any;
  totalCount: number;
  isChecked: boolean;
  visible: boolean;
  internalVisible: boolean;
  offset: number;
  checkedRows: any;
  pageLoading: boolean;
  current: number;
  checkedKeys: any;
  limit: number;
  columns: any;
  sendDoData: any;
  sequenceExecutionVisible: boolean;
  allPriorityDoIds: any[];
  missingMergedDosVisible: boolean,
}
class PreMadeLetter extends Component<Props, State> {
  allColumns: any = [
    {
      title: "平台序列号",
      dataIndex: "doId",
      key: "doId",
      render: (_: any, record: any) => (
        <>
          <span>{record.doId}</span>
          {record.doMerge ? <span className="do-merge">已拼单</span> : ""}
        </>
      )
    },
    {
      title: "合同编号",
      dataIndex: "contractNo",
      key: "contractNo"
    },
    {
      title: "合同行项目编号",
      dataIndex: "batch",
      key: "batch",
      render: (_: any, record: any) => {
        return cutBatchNoZero(record.batch);
      }
    },
    {
      title: "创建时间",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (_: any, record: any) => {
        return record.createdDate ? moment(record.createdDate).format("YYYY-MM-DD HH:mm") : "";
      }
    },
    {
      title: "买方",
      dataIndex: "buyer",
      key: "buyer"
    },
    {
      title: "产品名称",
      dataIndex: "productName",
      key: "productName",
      render: (_: any, record: any) => {
        return this.props.permissions.CNInternalUsers ? record.productName : record.materialSalesText;
      }
    },
    {
      title: "包装规格",
      dataIndex: "packageSpecificationCn",
      key: "packageSpec"
    },
    {
      title: "嘉吉发货地址",
      dataIndex: "shipAddressDesc",
      key: "shipAddressDesc",
      render: (_: any, record: any) => {
        return setShipAddressDesc(record);
      }
    },
    {
      title: "买方收货地址",
      dataIndex: "receiveAddressDesc",
      key: "receiveAddressDesc"
    },
    {
      title: "紧急/一次性买方收货地址",
      dataIndex: "emergencyReceiveAddress",
      key: "emergencyReceiveAddress"
    },
    {
      title: "计划提货数量",
      dataIndex: "doQuantity",
      key: "doQuantity"
    },
    {
      title: "计划提货数量（箱/件）",
      dataIndex: "doQuantityByPackage",
      key: "doQuantityByPackage",
      render: (_: any, record: any) => {
        return record.doQuantityByPackage || "-";
      }
    },
    {
      title: "计数单位",
      dataIndex: "packingUnitDesc",
      key: "packingUnitDesc"
    },
    {
      title: "车/船号",
      dataIndex: "truckPlateNumber",
      key: "truckPlateNumber"
    },
    {
      title: "运输方式",
      dataIndex: "transportTypeDesc",
      key: "transportTypeDesc"
    },
    {
      title: "车辆限载吨位",
      dataIndex: "truckLoadingLimit",
      key: "truckLoadingLimit",
      render: (_: any, record: any) => {
        return record.truckLoadingLimit || "";
      }
    },
    {
      title: "承运人姓名",
      dataIndex: "driverName",
      key: "driverName"
    },
    {
      title: "承运人联系方式",
      dataIndex: "driverContactNumber",
      key: "driverContactNumber"
    },
    {
      title: "买方自有合同号（选填）",
      dataIndex: "buyerContactNumber",
      key: "buyerContactNumber"
    },
    {
      title: "提单打印信息",
      dataIndex: "shippingRemark",
      key: "shippingRemark"
    },
    {
      title: "发货要求",
      dataIndex: "shippingInstruction",
      key: "shippingInstruction"
    },
    {
      title: "大提单",
      dataIndex: "bigOrder",
      key: "bigOrder",
      render: (_: any, record: any) => {
        return record.bigOrder && record.bigOrder === "Y" ? "是" : "否";
      }
    },
    {
      title: "预计提货日期",
      dataIndex: "estimatedDeliveryDate",
      key: "estimatedDeliveryDate",
      render: (_: any, record: any) => {
        return record.estimatedDeliveryDate && moment(record.estimatedDeliveryDate).format("YYYY-MM-DD");
      }
    },
    {
      title: "离厂状态短信通知",
      dataIndex: "smsRequired",
      key: "smsNotification",
      render: (_: any, record: any) => {
        return record.smsRequired ? "开启" : "不开启";
      }
    },
    {
      title: "保存时间",
      dataIndex: "modifiedDate",
      key: "modifiedDate",
      render: (_: any, record: any) => {
        return record.modifiedDate && moment(record.modifiedDate).format("YYYY-MM-DD HH:mm");
      }
    },
    {
      title: "拼单编号",
      dataIndex: "mergeRemark",
      key: "mergeRemark"
    },
    {
      title: "跨客户拼单",
      dataIndex: "customerMerge",
      key: "customerMerge",
      render: (_: any, record: any) => {
        return record.customerMerge ? "是" : "否"
      }
    },
    {
      title: "备注",
      dataIndex: "externalMemo",
      key: "externalMemo"
    },
    {
      title: "优先执行",
      dataIndex: "contractExecutionSequenceControl",
      key: "contractExecutionSequenceControl",
      render: (_: any, record: any) => {
        return record.contractExecutionSequenceControl ? "是" : "否";
      }
    },
    {
      title: "删除",
      align: "center" as "center",
      dataIndex: "delete",
      key: "delete",
      fixed: "right" as "right",
      render: (_: any, record: any) => <TableDelete data={record} cb_success={this.onHandleDeleteSuccess} />
    }
  ];
  public readonly state = {
    data: [],
    totalCount: 0,
    isChecked: true,
    checkedRows: [],
    checkedKeys: [],
    visible: false,
    internalVisible: false,
    offset: 0,
    pageLoading: false,
    current: 1,
    limit: 10,
    columns: [],
    sendDoData: [],
    sequenceExecutionVisible: false,
    allPriorityDoIds: [],
    missingMergedDosVisible: false,
  };

  componentDidMount() {
    document.title = "已预制提货委托函";
    this.getTableData();
    if (this.props.permissions.CNExternalUsers) {
      const columns = this.allColumns.filter(
        (val: any) => val.key !== "transportTypeDesc" && val.key !== "shippingInstruction"
      );
      this.setState({ columns: columns });
    } else if (this.props.permissions.CNInternalUsers) {
      const columns = this.allColumns.filter((val: any) => val.key !== "externalMemo");
      this.setState({ columns: columns });
    }
  }

  // 获取列表数据
  getTableData = (tag: any = "") => {
    const { current, data, limit, offset } = this.state;
    this.setState({ pageLoading: true });
    let newOffset = offset;
    if (tag === "delete") {
      if (current > 1 && data.length === 1) {
        newOffset = (current - 2) * limit;
      }
    }
    getSavedDeliveryOrders({
      offset: newOffset,
      customerCode: this.props.switcher.customerCode
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          if (tag === "delete" && current > 1 && data.length === 1) {
            this.setState({
              offset: newOffset,
              current: current - 1
            });
          }
          this.setState({
            data: Array.isArray(res.data.deliveryOrder) ? res.data.deliveryOrder : [],
            totalCount: res.data.metadata.totalCount,
            checkedKeys: [],
            checkedRows: []
          });
        }
      })
      .catch((exception: any) => {
        console.log(`getSavedDeliveryOrders Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        this.setState({
          data: [],
          totalCount: 0
        });
      })
      .finally(() => {
        this.setState({ pageLoading: false });
      });
  };

  // 删除后的回调
  onHandleDeleteSuccess = (doData: any) => {
    this.getTableData("delete");
  };

  // 获取选中行
  handleCheck = (key: any, rows: any) => {
    this.setState({
      isChecked: rows.length === 0,
      checkedRows: rows,
      checkedKeys: key
    });
  };

  // 分页
  onChange = (page: number) => {
    this.setState({ current: page });
  };

  mapSavedTime = (date: any) => {
    const chinaDateTime = convertUTCDateToChinaDate(date);
    return `${moment(chinaDateTime.date).format("YYYY-MM-DD")} ${chinaDateTime.time}`;
  };

  // 获取所有选中的平台序列号
  getSelectedDoIds = () => {
    const { checkedRows } = this.state;
    const selectedDoIds: any[] = [];
    checkedRows.forEach((row: any) => {
      if (row.doId) {
        selectedDoIds.push(row.doId);
      }
    });
    return selectedDoIds;
  };

  // 获取未选中的提货委托函
  getUnSelectedRows = () => {
    const { data } = this.state;
    const selectedDoIds = this.getSelectedDoIds();
    const unSelectedRows: any[] = [];
    data.forEach((data: any) => {
      if (selectedDoIds.indexOf(data.doId) === -1) {
        unSelectedRows.push(data)
      }
    });
    return unSelectedRows;
  };

  isEarlierDateInArray = (arrayOne: any, arrayTwo: any) => {
    // 遍历数组一
    for (const itemOne of arrayOne) {
      // 遍历数组二
      for (const itemTwo of arrayTwo) {
        // 比较两个对象的创建日期
        if (new Date(itemOne.createdDate) < new Date(itemTwo.createdDate)) {
          // 如果数组一中的对象创建日期更早，返回true
          return true;
        }
      }
    }
    // 如果没有找到更早的创建日期，返回false
    return false;
  };

  // 1、外部用户创建委托，自动copy合同发货要求 + 外部备注，并且拼接到提单打印信息后面
  // 2、内部用户创建委托，自动copy合同发货要求
  formatCheckedRows = (checkedRows: any) => {
    const { permissions } = this.props;
    return checkedRows.map((row: any) => {
      const baseShippingRemark = row.shippingRemark || "";
      let shippingInstruction = row.shippingInstruction || "";
      // 针对外部用户特殊处理
      if (permissions.CNExternalUsers && row.externalMemo) {
        shippingInstruction = shippingInstruction ? `${shippingInstruction}；用户备注：${row.externalMemo}` : row.externalMemo;
      }
      const shippingRemark = [baseShippingRemark, shippingInstruction]
        .filter(Boolean)
        .join('；')
        .trim();
      row.shippingInstruction = shippingInstruction;
      row.shippingRemark = shippingRemark;
      return row;
    });
  };

  /**
   * 检查优先执行顺序的逻辑
   * @param checkedRows 
   * @param unSelectedRows 
   * @param nonPriortyDos 
   * @param unSelectedPriortyDos 
   * @returns 
   */
  checkPriorityExecution = (
    checkedRows: any[],
    unSelectedRows: any[],
    nonPriortyDos: any[],
    unSelectedPriortyDos: any[]
  ): boolean => {
    // 如果没有选中的行，或者未选中的行为空（表示全选），直接返回false
    if (!checkedRows.length || !unSelectedRows.length) {
      return false;
    }

    // 判断两个委托函是否具有相同的基本属性
    const hasSameBasicProperties = (item1: any, item2: any): boolean => {
      const properties = ['orgCode', 'buyer', 'businessLine', 'productCode', 'packageSpecificationCn'];
      return properties.every(prop => item1[prop] === item2[prop]);
    };

    // 情况1：选中的行中包含非优先执行的提货委托函
    if (nonPriortyDos.length > 0) {
      return nonPriortyDos.some(nonPriorityItem =>
        unSelectedPriortyDos.some(priorityItem =>
          hasSameBasicProperties(priorityItem, nonPriorityItem) &&
          priorityItem.contractExecutionSequenceControl
        )
      );
    }

    // 情况2：选中的都是优先执行的提货委托函
    return checkedRows.some(checkedItem =>
      unSelectedPriortyDos.some(priorityItem =>
        hasSameBasicProperties(priorityItem, checkedItem) &&
        priorityItem.contractExecutionSequenceControl &&
        new Date(priorityItem.createdDate) < new Date(checkedItem.createdDate)
      )
    );
  };

  // 提交
  submitData = () => {
    const { checkedRows } = this.state;

    if (!checkedRows || checkedRows.length === 0) {
      return;
    }

    // 提交时：请先提交以下提货委托函，再提交其他委托函，条件：选择提交的提货委托中包含非优先执行且未选择的部分包含需要优先执行的，弹出这个提示。
    const unSelectedRows: any[] = this.getUnSelectedRows(); // 未选中的提货委托函
    // 选中的非优先执行的提货委托函
    const nonPriorityDos = checkedRows.filter((row: any) => {
      return !row.contractExecutionSequenceControl;
    });
    // 获取未选中的提货委托函中是否存在需要优先执行的
    const unSelectedPriorityDos = unSelectedRows.length > 0 ? unSelectedRows.filter((row: any) => row.contractExecutionSequenceControl) : [];
    
    if (this.checkPriorityExecution(checkedRows, unSelectedRows, nonPriorityDos, unSelectedPriorityDos)) {
      // 打开按序优先执行提示弹窗
      this.setState({ sequenceExecutionVisible: true });
      return;
    }

    // 同一组拼车的提单一起提交，否则会提交失败
    const mergedDos = checkedRows.filter((row: any) => {
      return row.doMerge && row.mergeGroup && row.mergeOrder;
    });
    if (mergedDos) {
      if (this.hasMissingMergedDos(mergedDos)) {
        // 打开拼单提交提示
        this.setState({ missingMergedDosVisible: true });
        return;
      }
    }
    this.setState({ pageLoading: true });
    const checkedRowsTemp = this.formatCheckedRows(checkedRows);
    this.doUpldateDos(checkedRowsTemp, "submitPreview")
  };

  hasMissingMergedDos = (mergedDos: any) => {
    const mergeDosGroup: any = {};
    let hasMissingMergedDosTemp = false;
    mergedDos.forEach((item: any) => {
      const groupKey = `${item.mergeGroup}_${item.mergeOrder}`
      if (Object.keys(mergeDosGroup).includes(groupKey)) {
        mergeDosGroup[groupKey].push(item);
      } else {
        mergeDosGroup[groupKey] = [item]
      }
    });
    if (Object.keys(mergeDosGroup).length) {
      hasMissingMergedDosTemp = Object.keys(mergeDosGroup).some((key: any) => {
        // 获取拼单组总的拼单数量
        const totalMergeDos = extractNumber(mergeDosGroup[key][0].mergeRemark);
        return totalMergeDos !== null && totalMergeDos > mergeDosGroup[key].length
      })
    }
    return hasMissingMergedDosTemp;
  };

  doUpldateDos = (checkedRows: any, type: string) => {
    const { permissions } = this.props;
    this.setState({ pageLoading: true });
    updateDos(checkedRows, { type })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        }
      })
      .catch(err => {
        console.log(`updateDos Failure: ${err}`);
      })
      .finally(() => {
        if (permissions.CNInternalUsers) {
          this.setState({ internalVisible: true, pageLoading: false });
        } else {
          this.setState({ visible: true, pageLoading: false });
        }
      });
  };

  onHandleModalCloseAndGetData = () => {
    this.setState({ visible: false });
    this.getTableData();
  };

  onHandleInternalModalCloseAndGetData = () => {
    this.setState({ internalVisible: false });
    this.getTableData();
  };

  // 关闭按序优先执行提示弹窗
  doCloseSequenceExecutionVisibleModal = () => {
    this.setState({ sequenceExecutionVisible: false });
  };

  public render() {
    const {
      data,
      totalCount,
      isChecked,
      current,
      visible,
      pageLoading,
      checkedRows,
      internalVisible,
      checkedKeys,
      sequenceExecutionVisible
    } = this.state;

    return (
      <div>
        <PageHeader className="page-header" title="已预制提货委托函">
          <NotificationTips />
        </PageHeader>
        <div className="premade-letter">
          <Spin spinning={pageLoading}>
            <Card>
              <div style={{ fontSize: 16, height: 32 }}>已预制提货委托函列表</div>
              <Table
                size="small"
                rowSelection={{
                  type: "checkbox",
                  fixed: true,
                  selectedRowKeys: checkedKeys,
                  onChange: this.handleCheck
                }}
                columns={this.state.columns}
                rowKey="doId"
                dataSource={data}
                scroll={{ x: "max-content" }}
                pagination={{
                  current: current,
                  total: totalCount,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  size: isMobileDevice() ? "small" : "default",
                  onChange: this.onChange
                }}
              />
              {/* <Pagination
                style={{ marginTop: 20, width: "100%", textAlign: "right" }}
                showQuickJumper
                showSizeChanger={false}
                size={isMobileDevice() ? "small" : "default"}
                onChange={this.onChange as any}
                total={totalCount}
                current={current}
              /> */}
              <div className="button" style={{ height: 30, marginTop: 20 }}>
                <Button
                  disabled={isChecked}
                  type="primary"
                  data-testid="submit-btn"
                  loading={pageLoading}
                  onClick={this.submitData}
                  style={{ float: "right", marginRight: 10 }}
                >
                  提交
                </Button>
                <CancelCombine
                  style={{ float: "right", marginRight: 10 }}
                  getTable={this.getTableData}
                  data={checkedRows}
                />
                <CombineButton
                  isPremadeletter
                  style={{ float: "right", marginRight: 10 }}
                  getTable={this.getTableData}
                  data={checkedRows}
                />
              </div>
            </Card>
          </Spin>
        </div>
        <Modal
          title="提交成功"
          visible={visible}
          centered
          maskClosable={false}
          onCancel={this.onHandleModalCloseAndGetData}
          footer={
            <div>
              <Button
                data-testid="external-cancel"
                onClick={this.onHandleModalCloseAndGetData}
                style={{ marginRight: 10 }}
              >
                继续停留
              </Button>
              <Link data-testid="external-submit" to={{ pathname: "/main/delivery/sapdeliverylist" }}>
                <Button type="primary">立即跳转</Button>
              </Link>
            </div>
          }
        >
          <span>您是否想要立即跳转至提货单列表页面或者继续停留在此页面？</span>
        </Modal>
        {/* 内部跳转提货委托函列表 */}
        <Modal
          title="提交成功"
          visible={internalVisible}
          centered
          maskClosable={false}
          onCancel={this.onHandleInternalModalCloseAndGetData}
          footer={
            <div>
              <Button
                data-testid="internal-cancel"
                onClick={this.onHandleInternalModalCloseAndGetData}
                style={{ marginRight: 10 }}
              >
                继续停留
              </Button>
              <Link data-testid="internal-submit" to={{ pathname: "/main/delivery/deliveryletterlist" }}>
                <Button type="primary">立即跳转</Button>
              </Link>
            </div>
          }
        >
          <span>您是否想要立即跳转至提货委托函列表页面或者继续停留在此页面？</span>
        </Modal>
        <Modal
          title="提示"
          visible={sequenceExecutionVisible}
          centered
          maskClosable={false}
          onCancel={this.doCloseSequenceExecutionVisibleModal}
          footer={
            <Button type="primary" onClick={this.doCloseSequenceExecutionVisibleModal}>
              确认
            </Button>
          }
        >
          存在比您所选择的提货委托函更早创建且需要优先执行的提货委托函，请优先提交
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  permissions: state.userPermissions,
  switcher: state.switcher
});

export default connect(mapStateToProps, null)(PreMadeLetter);
