import { Button, Card, message } from "antd";
import SearchFilter from "../components/searchFilter";
import TableList from "../components/tableList";
import { useState } from "react";
import { downloadContractDetail, getChopList } from "@/api/contractReport";
import { deviceWidth, downloadFile, isMobileDevice } from "@/utils";
import FieldSetting from "@/pages/common/fieldSetting";
import { saveContractFieldsOrder } from "@/api/constant";
import DownloadReport from "@/pages/common/downloadReport";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

interface Props {
  switcher: any;
  userPermissions: any;
  userDetails: any;
}

interface ChopParams {
  pageNumber: number,
  pageSize: number,
  orgCode?: string,
  requestNo?: string,
  customerCode?: string,
  status: string
}

const Processing = (props: Props) => {
  const { switcher, userPermissions, userDetails } = props;
  const [tableData, setTableData] = useState([]);
  const [fieldsDesc, setFieldsDesc] = useState([]);
  const [allFields, setAllFields] = useState([]);
  const [loading, setLoading] = useState(false);
  // 添加新的状态变量
  const [batchDownloadLoading, setBatchDownloadLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<ChopParams>({ pageNumber: 1, pageSize: 10, status: "processing" })
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
    showQuickJumper: true,
    size: deviceWidth() > 576 ? ("default" as any) : ("small" as any)
  });

  /**
   * 获取待签章列表
   * @param params 
   */
  const getProcessingList = (params: ChopParams) => {
    setLoading(true);
    getChopList(params)
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else if (res.data) {
          const tableData = Array.isArray(res.data.list) ? res.data.list : [];
          const allFields = JSON.parse(res.data.fieldsOrder.allFields);
          const fieldsDesc = JSON.parse(res.data.fieldsOrder.fieldDesc);
          const paginationTemp = {
            ...pagination,
            current: params.pageNumber,
            pageSize: params.pageSize,
            total: res.data.metadata?.total_count ?? 0
          };
          setTableData(tableData);
          setAllFields(allFields);
          setFieldsDesc(fieldsDesc);
          setPagination(paginationTemp);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        setTableData([]);
        setPagination({ ...pagination, current: 1, total: 0 });
      })
      .finally(() => {
        setLoading(false);
      })
  };

  /**
   * 字段设置
   * @param data 
   * @param dataSource 
   * @param setVisibleFun 
   */
  const getColumns = (data: any, dataSource: any, setVisibleFun: any) => {
    saveContractFieldsOrder({
      type: "chopProcess",
      fieldDesc: JSON.stringify(data.map((item: any) => dataSource.find((val: any) => val.value === item)).filter((val: any) => val))
    })
      .catch(() => {
        message.error("字段设置失败，请稍后重试！");
      })
      .finally(() => {
        setVisibleFun(false);
        getProcessingList(searchValue);
      });
  };

  /**
   * Table翻页回调函数
   * @param page 页码
   */
  const onPageChange = (page: number, pageSize: number) => {
    setSearchValue({
      ...searchValue,
      pageNumber: page
    })
    getProcessingList({
      ...searchValue,
      pageNumber: page,
      pageSize: pageSize,
      status: "processing",
    });
  };

  /**
   * 下载报表 ｜ 字段设置按钮
   * @returns 
   */
  const cardExtra = () => {
    return (
      <div className="hdp-uf hdp-uf-vc hdp-uf-ww card-extra">
        <div className="extra-total extra-item">
          <span>共 </span>
          <span>{pagination.total}</span>
          <span> 条</span>
        </div>
        <div className="extra-item">
          <Button
            type="primary"
            icon={<VerticalAlignBottomOutlined />}
            onClick={handleBatchDownload}
            disabled={selectedRowKeys.length === 0}
            loading={batchDownloadLoading}
            style={{ marginRight: 8 }}
          >
            批量下载合同
          </Button>
          <DownloadReport
            data={{ ...searchValue }}
            params={{ total: pagination.total }}
            filename="contract.xlsx"
            fileType="chop"
          />
        </div>
        {!isMobileDevice() && (
          <div className="extra-item">
            <FieldSetting fieldDesc={fieldsDesc} allFields={allFields} getColumns={getColumns} />
          </div>
        )}
      </div>
    );
  };

  /**
   * 处理表单查询
   * @param value 
   */
  const handleSearch = (value: any) => {
    setSearchValue({
      ...searchValue,
      ...value,
      pageNumber: 1,
    });
    getProcessingList({
      ...searchValue,
      ...value,
      pageNumber: 1,
    });
  };

  // 处理选择变化
  const handleSelectionChange = (selectedRowKeys: any[], selectedRows: any[]) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  // 批量下载合同
  const handleBatchDownload = async () => {
    setBatchDownloadLoading(true);
    try {
      const res = await downloadContractDetail(
        selectedRows.map(row => ({
          fileKey: row.fileKey,
          fileName: row.fileName
        }))
      );

      if (selectedRows.length === 1) {
        // 检查文件名是否有有效的后缀
        let fileName = selectedRows[0].fileName;
        const fileExtRegex = /\.[0-9a-z]+$/i;
        if (!fileExtRegex.test(fileName)) {
          fileName += '.pdf'; // 如果没有有效后缀，添加 .pdf
        }
        // 单个文件下载
        downloadFile(res, fileName || 'contract.pdf');
      } else {
        // 多个文件打包下载
        downloadFile(res, 'contracts.zip');
      }
      message.success('下载成功');
    } catch (error) {
      console.error('下载失败:', error);
      message.error('下载失败，请重试');
    } finally {
      setBatchDownloadLoading(false);
    }
  };

  const rowSelection = {
    type: "checkbox",
    fixed: true,
    selectedRowKeys,
    getCheckboxProps: (item: any) => {
      return { disabled: !item.fileKey };
    },
    onChange: handleSelectionChange
  };

  return (
    <div className="chop__pending">
      <SearchFilter onSearch={handleSearch}
        switcher={switcher}
        userPermissions={userPermissions}
        userDetails={userDetails}
      />
      <div className="table-container">
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
          bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
          title="待签章合同列表"
          headStyle={{
            backgroundColor: "white",
            borderBottom: "none"
          }}
          extra={cardExtra()}
        >
          <TableList
            type={"processing"}
            fieldsDesc={fieldsDesc}
            tableData={tableData}
            pagination={{
              ...pagination,
              onChange: onPageChange
            }}
            loading={loading}
            refreshList={() => getProcessingList(searchValue)}
            rowSelection={rowSelection}
          />
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userPermissions: state.userPermissions,
  switcher: state.switcher,
  userDetails: state.userDetails
});

export default connect(mapStateToProps, null)(Processing);