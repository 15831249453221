import { Table, Switch, Button } from "antd";
import { ShareAltOutlined, DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
import { isMobileDevice } from "@/utils/common";

interface Props {
  tableData: any;
  pagination: object;
  loading: boolean;
  loadingId: string | number | null;
  onStatusChange: (record: any, checked: boolean) => void;
  onShare: (record: any) => void;
  onDownload: (record: any) => void;
  onPreview: (record: any) => void;
}

const TableList: React.FC<Props> = (props) => {

  const columns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "问卷名称",
      dataIndex: "title",
      key: "title"
    },
    {
      title: "问卷模板",
      dataIndex: "temKey",
      key: "temKey"
    },
    {
      title: "问卷数量",
      dataIndex: "answerCount",
      key: "answerCount"
    },
    {
      title: "创建人",
      dataIndex: "operationBy",
      key: "operationBy"
    },
    {
      title: "创建时间",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text: string) => {
        return moment(text).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    {
      title: "问卷版本号",
      dataIndex: "recordVersion",
      key: "recordVersion"
    },
    {
      title: "状态",
      dataIndex: "status",
      fixed: isMobileDevice() ? false : "right" as const,
      align: "center",
      render: (_: any, record: any) => (
        <Switch
          loading={props.loadingId === record.id}
          checked={record.state === "open"}
          onChange={(checked) => props.onStatusChange?.(record, checked)}
          checkedChildren="激活"
          unCheckedChildren="失效"
        />
      )
    },
    {
      title: "分享",
      fixed: isMobileDevice() ? false : "right" as const,
      align: "center" as const,
      render: (_: any, record: any) => (
        <CopyToClipboard text={`${window.location.origin}/questionnairesurvey/${record.surveyKey}`}>
          <Button
            type="link"
            icon={<ShareAltOutlined />}
            onClick={() => props.onShare(record)}
            style={{padding: 0}}
          />
        </CopyToClipboard>
      )
    },
    {
      title: "下载记录",
      fixed: isMobileDevice() ? false : "right" as const,
      align: "center" as const,
      render: (_: any, record: any) => (
        <Button
          type="link"
          icon={<DownloadOutlined />}
          onClick={() => props.onDownload(record)}
          style={{padding: 0}}
        />
      )
    },
    {
      title: "预览",
      fixed: isMobileDevice() ? false : "right" as const,
      align: "center" as const,
      render: (_: any, record: any) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => props.onPreview(record)}
          style={{padding: 0}}
        />
      )
    }
  ];
  return (
    <div className="table-list">
      <Table
        rowKey="id"
        columns={columns as any}
        dataSource={props.tableData}
        size="small"
        scroll={{ x: "max-content" }}
        pagination={props.pagination}
        loading={props.loading}
      />
    </div>
  );
};

export default TableList;
