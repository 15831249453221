import React, { useState, useEffect } from 'react';
import { Input, Button, Form, Radio, Modal } from 'antd';
import { FormData, Section, SurveyData, SurveyProps } from '@/utils/surveyInitData/types';
import './index.scss';

// 问卷调查表单验证规则
const surveyFormRules = {
  // 基础信息部分
  baseInfo: {
    clientCompanyName: [
      { required: true, message: '请填写客户单位名称' }
    ],
    respondentName: [
      { required: true, message: '请填写姓名' }
    ],
    respondentContact: [
      { required: true, message: '请填写联系方式' },
      {
        validator: async (_: any, value: string) => {
          if (!value) return;

          const trimmedValue = value.replace(/\s/g, '');
          const mobilePattern = /^1\d{10}$/;
          const telPattern = /^(\d{3,4}-)?\d{7,8}$/;

          if (!mobilePattern.test(trimmedValue) && !telPattern.test(trimmedValue)) {
            return Promise.reject('请输入正确的手机号码或固定电话(如: 0513-12345678)');
          }
          return Promise.resolve();
        }
      }
    ],
    respondentPosition: [
      { required: true, message: '请选择您在公司中的角色' }
    ],
    otherPartnerCompany: [
      { required: true, message: '请填写其他供应商名称' }
    ]
  },

  // 评分问题部分
  ratingQuestions: {
    rating: [
      { required: true, message: '请选择评分' }
    ]
  },

  // 输入问题部分
  inputQuestions: {
    input: [
      { required: true, message: '请输入' }
    ]
  },

  // 布尔问题部分
  booleanQuestions: {
    boolean: [
      { required: true, message: '请选择答案' }
    ]
  }
};

// 动态生成问题验证规则的方法
const generateQuestionRules = (question: any) => {
  if (question.type === 'rating') {
    return question.required ? surveyFormRules.ratingQuestions.rating : [];
  }

  if (question.type === 'input') {
    return question.required ? surveyFormRules.inputQuestions.input : [];
  }

  if (question.type === 'boolean') {
    return question.required ? surveyFormRules.booleanQuestions.boolean : [];
  }

  return [];
};

const Survey: React.FC<SurveyProps> = ({ initialData, onSubmit, submitLoading, metaData, isSubmitted }) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<SurveyData>(initialData);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  useEffect(() => {
    // 组件加载时显示确认对话框
    setConfirmModalVisible(true);
  }, []);

  // 添加表单验证失败处理函数
  const handleFormValidationFailed = (errorInfo: any) => {
    Modal.error({
      title: '提交问卷失败',
      content: '问卷填写未完成',
      okText: '确定'
    });

    // 找到第一个错误的表单项并滚动到视图中
    const firstErrorField = errorInfo.errorFields[0];
    const firstErrorFieldName = firstErrorField.name[0]; // 获取错误字段的名称
    const formItem = document.getElementById(firstErrorFieldName);
    if (formItem) {
      formItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  // 生成表单初始值
  const initializeSurveyFormValues = () => {
    const initialValues: Record<string, any> = {
      clientCompanyName: initialData.clientCompanyName,
      respondentName: initialData.respondentName,
      respondentContact: initialData.respondentContact,
      clientType: initialData.companyType,
      respondentPosition: initialData.respondentPosition,
      otherPartnerCompany: initialData.otherPartnerCompany,
    };

    // 添加所有问题的答案和评论到初始值中
    initialData.surveyItems.forEach((section, sIndex) => {
      section.questions.forEach((question, qIndex) => {
        const inputKey = `question-${sIndex}-${qIndex}`;
        const commentKey = `comment-${sIndex}-${qIndex}`;

        if (question.type === 'input') {
          initialValues[inputKey] = question.result;
        }
        if (question.comments) {
          initialValues[commentKey] = question.comments.value;
        }
        if (question.type === 'rating') {
          if (!question.companies) {
            initialValues[`rating-${sIndex}-${qIndex}`] = question.score;
          }
        }
      });
    });

    // 添加 section 级别评论的初始值
    initialData.surveyItems.forEach((section, sIndex) => {
      if (section.comments) {
        initialValues[`section-comment-${sIndex}`] = section.comments.value;
      }
    });

    return initialValues;
  };

  // 更新竞争对手公司名称
  const updateOtherPartnerCompany = (value: string) => {
    const newData = { ...formData };
    newData.otherPartnerCompany = value;

    newData.surveyItems = newData.surveyItems.map(section => ({
      ...section,
      questions: section.questions.map(question => {
        if (question.type === 'rating' && question.companies) {
          return {
            ...question,
            companies: question.companies.map((company, index) => {
              if (index === 1) {
                return {
                  ...company,
                  company: value || '其他供应商'
                };
              }
              return company;
            })
          };
        }
        return question;
      })
    }));

    setFormData(newData);
  };

  // 处理表单字段变化
  const handleSurveyFieldChange = (field: string, value: string) => {
    if (field === 'otherPartnerCompany') {
      updateOtherPartnerCompany(value);
    } else {
      setFormData(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  // 处理职位选择
  const handleRespondentPositionChange = (position: string) => {
    setFormData(prev => ({
      ...prev,
      respondentPosition: position
    }));
  };

  // 处理表单提交
  const handleSurveySubmit = async () => {
    try {
      const values = await form.validateFields();

      const answerData: SurveyData = {
        clientCompanyName: values.clientCompanyName,
        respondentName: values.respondentName,
        respondentContact: values.respondentContact,
        companyType: values.clientType,
        respondentPosition: values.respondentPosition,
        otherPartnerCompany: values.otherPartnerCompany,
        surveyItems: formData.surveyItems.map((section, sIndex) => ({
          item: section.item,
          questions: section.questions.map((question, qIndex) => {
            if (question.type === 'rating') {
              const commentKey = `comment-${sIndex}-${qIndex}`;
              if (question.companies) {
                return {
                  type: 'rating' as const,
                  question: question.question,
                  companies: question.companies.map(company => ({
                    company: company.company,
                    score: company.score
                  })),
                  ...(question.comments ? {
                    comments: {
                      label: question.comments.label,
                      value: values[commentKey]
                    }
                  } : {})
                };
              } else {
                return {
                  type: 'rating' as const,
                  question: question.question,
                  score: question.score,
                  ...(question.comments ? {
                    comments: {
                      label: question.comments.label,
                      value: values[commentKey]
                    }
                  } : {})
                };
              }
            } else if (question.type === 'input') {
              const inputKey = `question-${sIndex}-${qIndex}`;
              return {
                type: 'input' as const,
                question: question.question,
                result: values[inputKey]
              };
            } else if (question.type === 'boolean') {
              const booleanKey = `question-${sIndex}-${qIndex}`;
              return {
                type: 'boolean' as const,
                question: question.question,
                result: values[booleanKey]
              };
            }
            return question;
          }),
          ...(section.comments ? {
            comments: {
              label: section.comments.label,
              value: values[`section-comment-${sIndex}`]
            }
          } : {})
        }))
      };

      const submitData: FormData = {
        title: metaData?.title || '',
        surveyKey: metaData?.surveyKey || '',
        answerText: JSON.stringify(answerData)
      }

      await onSubmit(submitData);
    } catch (error) {
      console.error('表单验证失败:', error);
    }
  };

  // 渲染评分问题
  const renderSurveySection = (section: Section, sectionIndex: number) => {
    return (
      <div className="rating-section">
        <div className="questions-list">
          <div className="section-header">
            <h3>{section.item}</h3>
          </div>
          {section.questions.map((question, qIndex) => {
            if (question.type === 'boolean') {
              return (
                <div key={`${sectionIndex}-${qIndex}`} className="question-item">
                  <Form.Item
                    label={question.question}
                    name={`question-${sectionIndex}-${qIndex}`}
                    rules={generateQuestionRules(question)}
                  >
                    <div className="boolean-options">
                      <Radio.Group>
                        <Radio value="是">是</Radio>
                        <Radio value="否">否</Radio>
                      </Radio.Group>
                    </div>
                  </Form.Item>
                </div>
              );
            }

            if (question.type === 'rating') {
              return (
                <div key={`${sectionIndex}-${qIndex}`} className="question-item">
                  <div className={`question-title ${question.required ? 'required' : ''}`}>{question.question}</div>
                  {question.companies ? (
                    <div className="rating-companies">
                      {question.companies.map((company, cIndex) => (
                        <div key={`${sectionIndex}-${qIndex}-${cIndex}`} className="company-rating">
                          <div className="company-name">
                            {cIndex === 0 ? '嘉吉' : (formData.otherPartnerCompany || '其他供应商')}
                          </div>
                          <Form.Item
                            name={`rating-${sectionIndex}-${qIndex}-${cIndex}`}
                            rules={generateQuestionRules(question)}
                          >
                            <div className="rating-options">
                              <Radio.Group
                                value={company.score}
                              >
                                {[...Array(10)].map((_, i) => (
                                  <Radio.Button
                                    key={i}
                                    value={10 - i}
                                    onClick={(e) => {
                                      const currentScore = 10 - i;
                                      const newData = { ...formData };
                                      const question = newData.surveyItems[sectionIndex].questions[qIndex];
                                      if (question.type === 'rating' && question.companies) {
                                        // 如果当前点击的评分与已选中的评分相同，则取消选中
                                        if (question.companies[cIndex].score === currentScore) {
                                          question.companies[cIndex].score = null;
                                        } else {
                                          question.companies[cIndex].score = currentScore;
                                        }
                                        form.setFieldsValue({ [`rating-${sectionIndex}-${qIndex}-${cIndex}`]: question.companies[cIndex].score });
                                        setFormData(newData);
                                      }
                                    }}
                                  >
                                    {10 - i}
                                  </Radio.Button>
                                ))}
                              </Radio.Group>
                              <div className="rating-labels">
                                <span>非常满意</span>
                                <span>非常不满意</span>
                              </div>
                            </div>
                          </Form.Item>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <Form.Item
                      name={`rating-${sectionIndex}-${qIndex}`}
                      rules={generateQuestionRules(question)}
                    >
                      <div className="rating-options">
                        <Radio.Group
                          value={question.score}
                        >
                          {[...Array(10)].map((_, i) => (
                            <Radio.Button
                              key={i}
                              value={10 - i}
                              onClick={(e) => {
                                const currentScore = 10 - i;
                                const newData = { ...formData };
                                const question = newData.surveyItems[sectionIndex].questions[qIndex];
                                if (question.score === currentScore) {
                                  question.score = null;
                                } else {
                                  question.score = currentScore;
                                }
                                form.setFieldsValue({ [`rating-${sectionIndex}-${qIndex}`]: question.score });
                                setFormData(newData);
                              }}
                            >
                              {10 - i}
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                        <div className="rating-labels">
                          <span>非常满意</span>
                          <span>非常不满意</span>
                        </div>
                      </div>
                    </Form.Item>
                  )}
                  {question.comments && (
                    <div className="question-comments">
                      <Form.Item
                        name={`comment-${sectionIndex}-${qIndex}`}
                        label={question.comments.label}
                      >
                        <Input.TextArea
                          rows={2}
                          placeholder="请输入"
                          maxLength={1024}
                        />
                      </Form.Item>
                    </div>
                  )}
                </div>
              );
            }

            if (question.type === 'input') {
              return (
                <div key={`${sectionIndex}-${qIndex}`} className="question-item">
                  <Form.Item
                    label={question.question}
                    name={`question-${sectionIndex}-${qIndex}`}
                    rules={generateQuestionRules(question)}
                  >
                    <Input.TextArea
                      rows={2}
                      placeholder="请输入"
                      maxLength={1024}
                    />
                  </Form.Item>
                </div>
              );
            }

            return null;
          })}

          {section.comments && (
            <div className="section-comments">
              <Form.Item
                name={`section-comment-${sectionIndex}`}
                label={section.comments.label}
              >
                <Input.TextArea
                  rows={2}
                  placeholder="请输入"
                  maxLength={1024}
                />
              </Form.Item>
            </div>
          )}
        </div>
      </div>
    );
  };

  // 渲染职位选项
  const renderRespondentPositions = () => {
    const positions = [
      '高级领导层（所有者，首席执行官，首席财务官，首席运营官，等等）',
      '销售（销售经理，销售代表）',
      '市场营销（市场营销经理，市场营销专员）',
      '采购',
      '供应链/物流',
      '技术/研究与开发',
      '财务/会计（财务经理，会计）',
      '客户支持/服务',
      '质量保证/质量控制/安全'
    ];

    return (
      <div className="role-selection-section">
        <Radio.Group
          value={formData.respondentPosition}
          onChange={(e) => handleRespondentPositionChange(e.target.value)}
        >
          {positions.map((position, index) => (
            <Radio key={index} value={position}>
              {position}
            </Radio>
          ))}
        </Radio.Group>
      </div>
    );
  };

  // 渲染其他合作伙伴输入框
  const renderOtherPartnerInput = () => {
    return (
      <div className="company-input-section">
        <div className="company-input">
          <Input
            value={formData.otherPartnerCompany || ''}
            onChange={e => handleSurveyFieldChange('otherPartnerCompany', e.target.value)}
            placeholder="请输入公司名称"
            maxLength={128}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="survey">
      <Form
        form={form}
        layout="vertical"
        className="survey-content"
        onFinish={handleSurveySubmit}
        onFinishFailed={handleFormValidationFailed}
        initialValues={initializeSurveyFormValues()}
      >
        <div className="survey-logo">
          <img src={metaData?.logo} alt="嘉吉logo" />
        </div>
        <div className="survey-title">
          {metaData?.title}
        </div>

        <Form.Item
          label="客户单位名称："
          name="clientCompanyName"
          rules={surveyFormRules.baseInfo.clientCompanyName}
        >
          <Input placeholder="请输入客户单位名称" maxLength={128} />
        </Form.Item>

        <Form.Item
          label="姓名："
          name="respondentName"
          rules={[{ required: true, message: '请填写姓名' }]}
        >
          <Input placeholder="请输入姓名" maxLength={128} />
        </Form.Item>

        <Form.Item
          label="联系方式："
          name="respondentContact"
          rules={surveyFormRules.baseInfo.respondentContact}
        >
          <Input placeholder="请输入手机号码或固定电话" />
        </Form.Item>

        {
          metaData?.type === '豆粕调查表' && (
            <Form.Item
              label="客户类型："
              name="clientType"
              rules={[{ required: true, message: '请选择客户类型' }]}
            >
              <div className="customer-type">
                <Radio.Group>
                  <Radio value="经销商">经销商</Radio>
                  <Radio value="饲料厂">饲料厂</Radio>
                </Radio.Group>
              </div>
            </Form.Item>
          )
        }

        <Form.Item
          name="respondentPosition"
          label="1、下列哪一项最能描述您在公司中的角色？"
          rules={surveyFormRules.baseInfo.respondentPosition}
        >
          {renderRespondentPositions()}
        </Form.Item>
        {
          ['豆粕调查表', '油调查表-FS&BR'].includes(metaData?.type || '') && (
            <Form.Item
              name="otherPartnerCompany"
              label="2、请列出您在过去一年中除嘉吉外采购最多的1家公司名称："
              rules={surveyFormRules.baseInfo.otherPartnerCompany}
            >
              {renderOtherPartnerInput()}
            </Form.Item>
          )
        }

        {["豆粕调查表", "油调查表-FS&BR"].includes(metaData?.type || '') && (
          <div className="description">
            <p><strong>请您代表贵公司对嘉吉和贵公司的其它供应商评分(非常满意为10分，非常不满意为1分，请点击选择您认为最合适的分数)。</strong></p>
          </div>
        )}

        {"油调查表-KA&FI" === metaData?.type && (
          <div className="description">
            <p><strong>请您代表贵公司对嘉吉评分(非常满意为10分，非常不满意为1分，请点击选择您认为最合适的分数)。</strong></p>
          </div>
        )}

        {formData.surveyItems.map((section, sectionIndex) => (
          <React.Fragment key={sectionIndex}>
            {renderSurveySection(section, sectionIndex)}
          </React.Fragment>
        ))}

        <div className="survey-footer">
          <div className="fax-tip">
            请于三日内提交您的反馈，非常感谢！
          </div>
          <div className="submit-container">
            <Button
              className='submit-button'
              type="primary"
              htmlType="submit"
              loading={submitLoading}
              disabled={isSubmitted}
            >
              提交问卷
            </Button>
          </div>
        </div>
      </Form>
      <Modal
        title="问卷说明"
        visible={confirmModalVisible}
        closable={false}
        centered
        footer={[
          <Button key="ok" type="primary" onClick={() => setConfirmModalVisible(false)}>
            我已了解
          </Button>
        ]}
      >
        <p style={{ fontSize: '14px', lineHeight: '1.6' }}>
          感谢您选择嘉吉粮油！为给您提供更优质的产品和服务，我们特拟定了本问卷。我们非常珍视您的宝贵意见，您的反馈将作为我们提升的重要依据！
        </p>
      </Modal>
    </div>
  );
};

export default Survey; 