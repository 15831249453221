import { Modal, Button, message } from 'antd';
import QRCode from 'qrcode.react';
import { CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import { useCallback, useRef } from 'react';
import './index.scss';

interface ShareModalProps {
  visible: boolean;
  url: string;
  title: string;
  onClose: () => void;
}

const ShareModal: React.FC<ShareModalProps> = ({ visible, url, title, onClose }) => {
  const qrCodeRef = useRef<HTMLDivElement>(null);

  /**
   * 复制链接到剪贴板
   */
  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(url).then(() => {
      message.success('问卷链接已复制到剪贴板');
    });
  }, [url]);

  /**
   * 下载二维码图片
   */
  const handleDownloadQRCode = useCallback(() => {
    if (!qrCodeRef.current) return;
    
    const canvas = qrCodeRef.current.querySelector('canvas');
    if (!canvas) return;

    // 使用问卷标题作为文件名
    const fileName = `${title}.png`;
    
    // 创建一个临时链接来下载
    const link = document.createElement('a');
    link.download = fileName;
    link.href = canvas.toDataURL('image/png');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [title]);

  return (
    <Modal
      title="分享问卷"
      visible={visible}
      footer={null}
      onCancel={onClose}
      centered
    >
      <div className="share-modal-content">
        <h3 className="survey-title">{title}</h3>
        <div ref={qrCodeRef} className="qr-code-wrapper">
          <QRCode
            value={url}
            size={200}
            level="H"
            includeMargin
          />
        </div>
        <p className="scan-tip">扫描二维码填写问卷</p>
        <p className="url-wrapper">
          问卷链接: {url}
        </p>
        <div className="button-group">
          <Button 
            icon={<CopyOutlined />} 
            onClick={handleCopyLink}
          >
            复制链接
          </Button>
          <Button 
            icon={<DownloadOutlined />} 
            onClick={handleDownloadQRCode}
          >
            下载二维码
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;