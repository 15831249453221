import RequestService from "./request";

/**
 * 创建问卷
 */
export const createSurveyApi = (dataObj: object) =>
  RequestService({
    url: "/notificationsap/survey/saveOrUpdate",
    method: "post",
    data: dataObj
  });

/**
 * 获取问卷列表
 */
export const getSurveyListApi = (dataObj: object) =>
  RequestService({
    url: "/notificationsap/survey/page",
    method: "get",
    params: dataObj
  });

/**
 * 下载问卷填写记录
 */
export const downloadSurveyAnswerExcelApi = (dataObj: object) =>
  RequestService({
    url: "/notificationsap/survey/answerExcel",
    method: "get",
    params: dataObj,
    responseType: "blob"
  });

/**
 * 更新问卷状态
 */
export const updateSurveyStatusApi = (dataObj: object) =>
  RequestService({
    url: "/notificationsap/survey/updateState",
    method: "post",
    data: dataObj
  });

/**
 * 填写后提交问卷
 */
export const saveSurveyAnswerApi = (dataObj: object) =>
  RequestService({
    url: "/notificationsap/survey/saveSurveyAnswer",
    method: "post",
    data: dataObj
  });
