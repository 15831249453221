import { Card, PageHeader, message } from "antd";
import NotificationTips from "../common/notificationTips";
import TableList from "./components/tableLIst";
import { useCallback, useEffect, useState, useRef } from "react";
import { deviceWidth } from "@/utils";
import SurveyConfiguration, { SurveyConfigurationRef } from "./components/surveyConfiguration";
import { getSurveyListApi, updateSurveyStatusApi, createSurveyApi, downloadSurveyAnswerExcelApi } from "@/api/survey";
import { downloadFile } from "@/utils";
import { getFilename } from "@/utils/formatUtils";
import ShareModal from "./components/shareModal";
import "./index.scss";

const pagination = {
  current: 1,
  total: 0,
  showSizeChanger: true,
  showQuickJumper: true,
  size: deviceWidth() > 576 ? ("default" as any) : ("small" as any),
}

/**
 * 问卷管理组件
 * @returns React组件
 */
const SurveyManagement: React.FC = () => {
  const [tableData, setTableData] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState<string | number | null>(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const surveyConfiguration_Ref = useRef<SurveyConfigurationRef>(null);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [shareTitle, setShareTitle] = useState('');

  /**
   * 渲染卡片额外信息
   * @returns 包含总条数的React元素
   */
  const cardExtra = useCallback(() => {
    return (
      <div className="hdp-uf hdp-uf-vc hdp-uf-ww card-extra">
        <div className="extra-total extra-item">
          <span>共 </span>
          <span>{totalPageNumber}</span>
          <span> 条</span>
        </div>
      </div>
    );
  }, [totalPageNumber]);

  /**
   * 处理表格分页变化
   * @param page 当前页码
   */
  const onPaginationChange = (page: number) => {
    setCurrentPageNumber(page);
  };

  /**
   * 获取问卷列表数据
   */
  const getSurveyList = useCallback(() => {
    setLoading(true);
    getSurveyListApi({})
      .then((res: any) => {
        if (res.code === "99999") {
          message.error(res.errorMsg);
        } else {
          setTableData(res.data || []);
          setTotalPageNumber(res.data?.length || 0);
        }
      })
      .catch((exception: any) => {
        console.log(`获取问卷列表失败: ${exception}`);
        message.error("获取问卷列表失败，请稍后重试");
        setTableData([]);
        setTotalPageNumber(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  /**
   * 处理创建问卷
   * @param values 创建问卷的表单数据
   */
  const handleCreate = useCallback((values: any) => {
    setSaveLoading(true);
    createSurveyApi(values)
      .then((res: any) => {
        if (res.code === "99999") {
          message.error(res.errorMsg);
        } else {
          message.success("创建问卷成功");
          // 重新获取问卷列表
          getSurveyList();
          // 清空表单
          surveyConfiguration_Ref.current?.resetFields();
        }
      })
      .catch((error: any) => {
        console.error("创建问卷失败:", error);
        message.error("创建问卷失败，请稍后重试");
      })
      .finally(() => {
        setSaveLoading(false);
      });
  }, [getSurveyList]);

  /**
   * 处理问卷状态变更
   * @param record 当前操作的问卷记录
   * @param checked 切换后的状态值
   */
  const handleStatusChange = useCallback(async (record: any, checked: boolean) => {
    // 设置loading状态
    setLoadingId(record.id);

    try {
      await updateSurveyStatusApi({
        id: record.id,
        state: checked ? "open" : "close"
      });

      message.success('状态更新成功');
      // 重新获取问卷列表
      getSurveyList();
    } catch (error) {
      message.error('状态更新失败');
      console.error('更新问卷状态���错:', error);
    } finally {
      // 清除loading状态
      setLoadingId(null);
    }
  }, [getSurveyList]);

  /**
   * 处理问卷分享
   * @param record 当前操作的问卷记录
   */
  const handleShare = useCallback((record: any) => {
    const surveyUrl = `${window.location.origin}/questionnairesurvey/${record.surveyKey}`;
    setShareUrl(surveyUrl);
    setShareTitle(record.title);
    setShareModalVisible(true);
  }, []);

  /**
   * 处理问卷填写记录下载
   * @param record 当前操作的问卷记录
   */
  const handleDownload = useCallback(async (record: any) => {
    try {
      const response = await downloadSurveyAnswerExcelApi({
        surveyKey: record.surveyKey
      });
      
      if (response) {
        // 使用当前时间作为文件名的一部分
        const fileName = getFilename(response.headers);
        downloadFile(response, fileName);
        message.success('下载成功');
      } else {
        message.error('下载失败，请稍后重试');
      }
    } catch (error) {
      console.error('下载问卷填写记录失败:', error);
      message.error('下载失败，请稍后重试');
    }
  }, []);

  /**
   * 处理问卷预览
   * @param record 当前操作的问卷记录
   */
  const handlePreview = useCallback((record: any) => {
    const previewUrl = `/questionnairesurvey/${record.surveyKey}?preview=true`;
    window.open(previewUrl, '_blank');
  }, []);

  useEffect(() => {
    document.title = "问卷列表";
    getSurveyList();
  }, [getSurveyList]);


  return (
    <div className="survey-management">
      <PageHeader className="page-header" title="问卷列表">
        <NotificationTips />
      </PageHeader>
      <div className="survey-management-content">
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
          bodyStyle={{ paddingTop: 0 }}
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
          title="问卷列表"
          extra={cardExtra()}
        >
          <TableList
            tableData={tableData}
            pagination={{
              ...pagination,
              current: currentPageNumber,
              total: totalPageNumber,
              onChange: onPaginationChange
            }}
            onStatusChange={handleStatusChange}
            loading={loading}
            loadingId={loadingId}
            onShare={handleShare}
            onDownload={handleDownload}
            onPreview={handlePreview}
          />
        </Card>
        <Card
          style={{ boxShadow: "0 5px 9px #dedbdb", marginTop: 20 }}
          bodyStyle={{ paddingTop: 0 }}
          title="创建问卷"
          headStyle={{
            backgroundColor: "white",
            fontWeight: 100,
            borderBottom: "none"
          }}
        >
          <SurveyConfiguration
            ref={surveyConfiguration_Ref}
            onCreate={handleCreate}
            loading={saveLoading}
          />
        </Card>
        <ShareModal 
          visible={shareModalVisible}
          url={shareUrl}
          title={shareTitle}
          onClose={() => setShareModalVisible(false)}
        />
      </div>
    </div>
  );
};

export default SurveyManagement;
