import { SurveyData } from './types';

export const oilSurveyKAFI: SurveyData = {
  "clientCompanyName": "",
  "respondentName": "",
  "respondentContact": "",
  "companyType": "",
  "respondentPosition": "",
  "otherPartnerCompany": "",
  "surveyItems": [
    {
      "item": "绩效指标",
      "questions": [
        {
          "question": "2、您有多大可能向同行或同事推荐嘉吉？",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "3、总体来说，您对嘉吉的满意程度如何？",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "4、考虑到所提供的产品和服务，您如何评价嘉吉为您的业务提供的整体价值？",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "5、您在多大程度上认同嘉吉是您的首选合作伙伴？",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "6、和嘉吉合作让您感受如何？",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "7、和嘉吉合作有多简便？",
          "type": "rating",
          "required": true,
          "score": null
        }
      ]
    },
    {
      "item": "整体感受",
      "questions": [
        {
          "question": "8、嘉吉是一家可以信任的公司吗？",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "9、嘉吉雇佣了一流的人才吗？",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "10、嘉吉以客户利益为首吗？",
          "type": "rating",
          "required": true,
          "score": null
        }
      ]
    },
    {
      "item": "可持续发展",
      "questions": [
        {
          "question": "11、嘉吉展现了对环境和社会可持续发展的领导力吗？",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "12、您目前是否正在参加嘉吉的可持续发展项目？",
          "type": "boolean",
          "required": true,
          "result": null
        },
        {
          "question": "13、您是否对嘉吉的可持续发展项目有兴趣？",
          "type": "boolean",
          "required": true,
          "result": null
        }
      ]
    },
    {
      "item": "创新",
      "questions": [
        {
          "question": "14、当您在寻求创新解决方案时，嘉吉是否是您的首选供应商？",
          "type": "rating",
          "required": true,
          "score": null
        }
      ]
    },
    {
      "item": "嘉吉销售人员",
      "questions": [
        {
          "question": "15、能有效地解决您的业务问题吗？",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "16、能提供正向的体验吗？",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "17、能得到响应吗？",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "18、是积极主动的吗？",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "19、能提供相关的知识、见解和经验吗？",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "20、是值得信任的吗？",
          "type": "rating",
          "required": true,
          "score": null
        }
      ],
      "comments": {
        "label": "对于嘉吉销售人员，您的意见或建议：",
        "value": ""
      }
    },
    {
      "item": "产品质量和服务水平",
      "questions": [
        {
          "question": "21、食品安全",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "22、产品质量：指标符合性",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "23、产品质量：应用性能表现",
          "type": "rating",
          "required": true,
          "score": null,
          "comments": {
            "label": "关于食品安全和产品质量，您的意见或建议：",
            "value": ""
          }
        },
        {
          "question": "24、技术服务：技术服务人员专业程度 ",
          "type": "rating",
          "required": true,
          "score": null,
          "comments": {
            "label": "关于技术服务，您的意见或建议：",
            "value": ""
          }
        },
        {
          "question": "25、财务服务：现金入账/清账时效性等",
          "type": "rating",
          "required": true,
          "score": null,
          "comments": {
            "label": "关于财务服务，您的意见或建议：",
            "value": ""
          }
        },
        {
          "question": "26、自提发货：发货人员服务质量",
          "type": "rating",
          "required": false,
          "score": null,
          "comments": {
            "label": "关于自提发货，您的意见或建议：",
            "value": ""
          }
        },
        
        {
          "question": "27、配送服务：配送及时性/车辆卫生等",
          "type": "rating",
          "required": false,
          "score": null,
          "comments": {
            "label": "关于配送服务，您的意见或建议：",
            "value": ""
          }
        },
        {
          "question": "28、售后服务：服务态度/投诉处理的及时性等",
          "type": "rating",
          "required": true,
          "score": null,
          "comments": {
            "label": "关于售后服务，您的意见或建议：",
            "value": ""
          }
        },
        {
          "question": "29、目前嘉吉提供的产品和服务外，您还有其它哪些需求吗？",
          "type": "input",
          "required": false,
          "result": ""
        }
      ]
    },
    {
      "item": "数字化客户端",
      "questions": [
        {
          "question": "30、数字化客户端-余额/欠款/对账单查询，现金入账/清账时效性等",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "31、数字化-合同明细查询",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "32、数字化-自主开单",
          "type": "rating",
          "required": true,
          "score": null
        },
        {
          "question": "33、您认为在与嘉吉的业务合作中，嘉吉的哪些方面做得最好？",
          "type": "input",
          "required": false,
          "result": ""
        },
        {
          "question": "34、您希望嘉吉在哪些方面能做得更好？",
          "type": "input",
          "required": false,
          "result": ""
        }
      ]
    }
  ]
};
