import { Component } from "react";
import { connect } from "react-redux";
import { ConfigProvider, Table, Card, Modal, Button, message } from "antd";
import { UnlockFilled, LockFilled } from "@ant-design/icons";
import moment from "moment";
import { getCIDOSContracts, setLockUnlockContract } from "@/api/deliveryOrder";
import {
  connectMaterialTextAndSpec,
  cutBatchNoZero,
  expandDelayDateDisplay,
  getProductNameInitValue,
  formatValue
} from "@/utils/formatUtils";
import { daysBetween, isHaveRolePermissionFeedFactory, isHaveThisPermission, isMobileDevice } from "@/utils/common";
import { saveContractFieldsOrder } from "@/api/constant";
import ChangeContractModal from "../../components/ChangeContractModal";
import ProductNameSelect from "@/pages/common/productNameSelect";
import FieldSetting from "@/pages/common/fieldSetting";
import MobileTableOperation from "@/pages/common/mobileTableOperation";

interface MyProps {
  refGo: (availableProperties: any) => void;
  setContractCheckedRow: (contractRowData: any) => void;
  resetAdvanceSearch: () => void;
  userPermissions: any;
  userDetails: any;
  switcher: any;
  searchParams: any;
  doDataSource: any;
}

interface MyState {
  sourceContractTableData: any[];
  contractTableData: any[];
  tableColumns: any;
  AllTableColumns: any;
  tableRow: any;
  metadata: any;
  fieldsDesc: any;
  allFields: any;
  searchValue: any;
  loading: boolean;
  tableCheckedKeys: any;
  current: number;
  showCheckConfirmModel: boolean;
  checkedRow: any;
  checkSelectedProductInfo: any;
  mulProductsContractInfoTemp: any;
  latestContractMulProductDoCreateItem: any;
  changeInfo: any;
  noChangeInfo: any;
  isChange: boolean;
  changeTipsType: string;
}

interface DataType1 {
  // 待执行合同数据类型
  actualBatch: string;
  contractNo: string;
  batch: string;
  actualRemainingDoQty: number;
  businessLine: string;
  buyer: string;
  productName: string;
  productCode: string;
  packageSpecification: string;
  contractTotalQty: number;
  batchVolume: number;
  actualRemainingDoNumber: number;
  remainingAvailableDoQty: number;
  approvedDoQty: number;
  savedAndPendingDoQty: number;
  unbilledAmount: number;
  deliveryLocation: string;
  executionStartDate: string;
  executionEndDate: string;
  plantCode: string;
  destinationLocationDescription: string;
  unLocked: string;
}

class ContractList extends Component<MyProps, MyState> {
  public readonly state = {
    sourceContractTableData: [], // 所有待执行合同数据
    contractTableData: [], // 待执行合同数据
    tableColumns: [], // 待执行合同columns
    AllTableColumns: [], // 待执行合同AllColumns
    tableRow: [] as any, // 待执行合同选中数据
    tableCheckedKeys: [] as any, // 当前选中的合同项的keys
    checkSelectedProductInfo: {} as any, // 单独记录当前操作的物料信息，用于提示展示用
    mulProductsContractInfoTemp: {} as any, // 存放多物料需要提示时，临时存储当前选中项的数据，只有在确认的情况下再赋值给正是变量
    checkedRow: {} as any, // 选中数据
    searchValue: {} as any,
    metadata: { totalCount: 0 },
    fieldsDesc: [],
    allFields: [],
    loading: false,
    current: 1,
    showCheckConfirmModel: false,
    isChange: false, // 按合同顺序执行弹框
    changeInfo: {} as any, // 记录要改变的合同信息
    noChangeInfo: {} as any, // 记录原本合同信息
    latestContractMulProductDoCreateItem: {} as any, //只记录最近一次选中项的切换记录 && 查询条件没变的情况下 -- lastChangeProductName
    changeTipsType: "1" // 默认带执行合同开单顺序检查提示类型为1
  };

  componentDidUpdate(preProps: any) {
    const { searchParams, doDataSource } = this.props;
    // 查询条件变更并提交时
    if (searchParams !== preProps.searchParams) {
      // 直接清除已有的选中数据并通知父级组件
      this.doClearChecked();
      this.doSetContractCheckedRow({});
      // 更新表单初始化的数据
      this.doGetCIDOSContracts(null, "searchParamsChange");
    }

    // 如果点击了已建立的提货委托函列表中的编辑操作，则需要清空当前合同列表中的选中项
    if (doDataSource !== preProps.doDataSource && doDataSource?.doId) {
      this.doClearChecked();
    }
  }

  componentDidMount() {
    // 初始化待执行合同表头
    this.doSetInitColumnsData();
    // 绑定自定义的 RefGo，可将要暴露给父级组件的相关属性汇总在此处
    this.props.refGo({
      updateContractList: this.doGetCIDOSContracts
    });
  }

  // 向父级返回当前选中的列表项
  doSetContractCheckedRow = (rowData: any = {}) => {
    this.props?.setContractCheckedRow(rowData);
  };

  // 检查新建或者编辑后是否需要更新合同列表数据
  checkIsSaveNeedUpdateList = (actionData: any) => {
    const { contractTableData } = this.state;
    if (contractTableData && contractTableData.length > 0) {
      const existItem = contractTableData.filter((item: any) => item?.contractNo === actionData.contractNo);
      if (existItem && existItem.length > 0) {
        // 更新列表数据
        return true;
      }
    }
    return false;
  };

  // 主动清空选中项
  doClearChecked = () => {
    this.setState({ tableCheckedKeys: [], checkedRow: {}, tableRow: [] });
  };

  // 初始化待执行合同可显示的字段设置
  doSetInitColumnsData = () => {
    const { userPermissions } = this.props;
    let columnsData: any[] = [
      { title: "合同编号", dataIndex: "contractNo", key: "contractNo" },
      {
        title: "合同行项目编号",
        dataIndex: "batch",
        key: "batch",
        render: (_: any, record: DataType1) => {
          return cutBatchNoZero(record.batch);
        }
      },
      { title: "业务类型代码", dataIndex: "divisionCode", key: "divisionCode" },
      { title: "业务类型", dataIndex: "businessLine", key: "businessLine" },
      { title: "卖方", dataIndex: "plant", key: "siteCode" },
      { title: "买方", dataIndex: "buyer", key: "buyer" },
      {
        title: "产品名称",
        dataIndex: "productName",
        key: "productName",
        render: (_: any, record: any) => {
          return (
            <ProductNameSelect
              data={record}
              onChange={this.doChangeProductName}
              initValue={getProductNameInitValue(record.productList[0])}
            />
          );
        }
      },
      {
        title: "产品代码",
        dataIndex: "productCode",
        key: "productCode",
        render: (_: any, record: any) => {
          return record?.productCode?.replace(/\b(0+)/gi, "");
        }
      },
      { title: "包装规格", dataIndex: "packageSpecificationCn", key: "package" },
      {
        title: "计数单位",
        dataIndex: "packingUnitDesc",
        key: "packingUnitDesc",
        render: (_: any, record: any) => {
          return formatValue(record.packingUnitDesc, "");
        }
      },
      {
        title: "合同数量",
        dataIndex: "batchVolume",
        key: "batchVolume",
        render: (_: any, record: DataType1) => {
          return formatValue(record?.batchVolume?.toFixed(3), "-");
        }
      },
      {
        title: "剩余可预制开单量",
        dataIndex: "actualRemainingDoQty",
        key: "actualRemainingDoQty",
        render: (_: any, record: DataType1) => {
          return formatValue(record?.actualRemainingDoQty?.toFixed(3), "-");
        }
      },
      {
        title: "剩余可预制开单量（件）",
        dataIndex: "actualRemainingDoNumber",
        key: "actualRemainingDoNumber",
        render: (_: any, record: any) => {
          return formatValue(record.actualRemainingDoNumber, "-");
        }
      },
      {
        title: "未开单量",
        dataIndex: "remainingAvailableDoQty",
        key: "remainingAvailableDoQty",
        render: (_: any, record: DataType1) => {
          return formatValue(record?.remainingAvailableDoQty?.toFixed(3), "-");
        }
      },
      {
        title: "已开单量",
        dataIndex: "approvedDoQty",
        key: "approvedDoQuantity",
        render: (_: any, record: DataType1) => {
          return formatValue(record?.approvedDoQty?.toFixed(3), "-");
        }
      },
      {
        title: "已预制开单量",
        dataIndex: "savedAndPendingDoQty",
        key: "savedPendingDoQty",
        render: (_: any, record: DataType1) => {
          return formatValue(record?.savedAndPendingDoQty?.toFixed(3), "-");
        }
      },
      {
        title: "结算单价",
        dataIndex: "unbilledAmount",
        key: "priceNet",
        render: (_: any, record: any) => {
          return isHaveRolePermissionFeedFactory() || record.contractType === "暂定价合同" || !record.unbilledAmount
            ? "-"
            : record.unbilledAmount.toFixed(2);
        }
      },
      { title: "结算单价单位", dataIndex: "settlementPriceUnitCn", key: "settlementPriceUnitCn" },
      {
        title: "买方收货地址",
        dataIndex: "destinationLocationDescription",
        key: "receiveAddress",
        render: (_: any, record: any) => {
          return formatValue(record.destinationLocationDescription, "");
        }
      },
      { title: "交货地点", dataIndex: "incotermsLocationName", key: "incotermsLocationName" },
      {
        title: "自提/配送",
        dataIndex: "shipmentMethod",
        key: "shipmentMethod",
        render: (_: any, record: any) => {
          return formatValue(record.shipmentMethod, "");
        }
      },
      {
        title: "预提",
        dataIndex: "withholdingDate",
        key: "withholdingDate",
        render: (_: any, record: any) => {
          return expandDelayDateDisplay(record.withholdingDate);
        }
      },
      { title: "提货开始日期", dataIndex: "executionStartDate", key: "executionStartDate" },
      { title: "提货截止日期", dataIndex: "executionEndDate", key: "executionEndDate" },
      {
        title: "延期",
        dataIndex: "delayDate",
        key: "delayDate",
        render: (_: any, record: any) => {
          return expandDelayDateDisplay(record.delayDate);
        }
      },
      {
        title: "有效延期",
        dataIndex: "validDelayDate",
        key: "validDelayDate",
        render: (_: any, record: any) => {
          return expandDelayDateDisplay(record.validDelayDate);
        }
      }
    ];

    // 内部用户单独处理逻辑
    if (userPermissions.CNInternalUsers) {
      columnsData.push({
        title: "嘉吉发货地址",
        dataIndex: "shipAddressDesc",
        key: "shipAddress",
        render: (_: any, record: any) => {
          return record.deliveryLocationCode && record.deliveryLocation
            ? `${record.deliveryLocationCode} (${record.deliveryLocation})`
            : "";
        }
      });
      columnsData = this.addOperateColumns(columnsData);
    }

    // 外部用户单独处理逻辑
    if (userPermissions.CNExternalUsers) {
      columnsData.push({ title: "嘉吉发货地址", dataIndex: "deliveryLocation", key: "shipAddress" });
    }

    this.setState({ tableColumns: columnsData, AllTableColumns: columnsData });
  };

  addOperateColumns = (columns: any) => {
    const { userPermissions } = this.props;
    const newColumns: any = columns;
    if (userPermissions.LockContract) {
      if (isMobileDevice()) {
        newColumns.push({
          title: "操作",
          align: "center" as "center",
          dataIndex: "active",
          key: "active",
          fixed: "right" as "right",
          render: (_: any, record: DataType1, index: number) => {
            return (
              <MobileTableOperation left={true}>
                {record.unLocked ? (
                  <Button data-testid="lock-btn" onClick={this.setLock(record, "lock")}>
                    锁
                  </Button>
                ) : (
                  <Button data-testid="lock-btn" onClick={this.setLock(record, "unlock")}>
                    解锁
                  </Button>
                )}
                <Button>立即创建</Button>
              </MobileTableOperation>
            );
          }
        });
      } else {
        newColumns.push({
          title: "解锁",
          align: "center" as "center",
          dataIndex: "active",
          key: "active",
          fixed: "right" as "right",
          render: (_: any, record: DataType1) => {
            return record.unLocked ? (
              <UnlockFilled
                data-testid="lock-btn"
                style={{ color: "#007681" }}
                onClick={this.setLock(record, "lock")}
              />
            ) : (
              <LockFilled data-testid="lock-btn" onClick={this.setLock(record, "unlock")} />
            );
          }
        });
      }
    }
    return newColumns;
  };

  // 获取待执行合同列表数据
  //  actionData有值时，需要判断被操作的数据的合同号是否在列表中，有才进行列表更新
  doGetCIDOSContracts = (actionData?: any, updateType?: string) => {
    const { searchParams } = this.props;
    // 查询需要筛选条件中设置了orgCode、customerCode
    if (!searchParams.orgCode || !searchParams.customerCode) {
      this.doResetExecutoryContractData();
      return;
    }
    // 如果是需要判断被操作的数据
    if (actionData && Object.keys(actionData).length > 0 && !this.checkIsSaveNeedUpdateList(actionData)) {
      return;
    }
    // 获取需要传给后端的参数
    const searchParamsTemp = {
      customerCode: searchParams.customerCode,
      orgCode: searchParams.orgCode,
      divisionCode: searchParams.divisionCode,
      productCode: searchParams.productCode,
      location: searchParams.location
    };

    this.setState({ loading: true });
    getCIDOSContracts({ ...searchParamsTemp })
      .then((res: any) => {
        if (res?.data?.contractList) {
          this.reportSuccess(res, updateType);
        } else {
          this.doResetExecutoryContractData();
        }
      })
      .catch((exception: any) => {
        console.log(`doGetCIDOSContracts Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
        this.doResetExecutoryContractData();
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  // 根据高级筛选条件过滤待执行合同列表
  getFilterContractListData = (sourceContractListData: any, filterParams: any) => {
    return sourceContractListData.filter((contractItem: any) => {
      return (
        (!filterParams.contractNo || contractItem.contractNo?.includes(filterParams.contractNo)) &&
        (!filterParams.batch || cutBatchNoZero(contractItem.batch) === filterParams.batch) &&
        (!filterParams.packageSpecification || contractItem.packageSpecificationCn?.includes(filterParams.packageSpecification)) &&
        (!filterParams.shipmentMethod || filterParams.shipmentMethod === "All" || contractItem.shipmentMethod === filterParams.shipmentMethod)
      )
    });
  };

  // 处理待执行合同列表数据-成功后
  reportSuccess = (res: any, updateType?: string) => {
    const { latestContractMulProductDoCreateItem, current } = this.state;
    const { contractList, fieldsOrder, metadata } = res.data;
    const { userPermissions, searchParams } = this.props;
    let fieldDesc: any = [];
    let allFields: any = [];
    if (fieldsOrder) {
      fieldDesc = JSON.parse(fieldsOrder.fieldDesc);
      allFields = JSON.parse(fieldsOrder.allFields);
      const externalUsersIgnoreFields: any = ["withholdingDate", "delayDate", "validDelayDate", "productCode"];
      if (userPermissions.CNExternalUsers) {
        fieldDesc = fieldDesc.filter((val: any) => !externalUsersIgnoreFields.includes(val.value));
        allFields = allFields.filter((val: any) => !externalUsersIgnoreFields.includes(val.value));
      }
      const data = fieldDesc.map((val: any) => val.value);
      this.getNewColumns(data);
    }

    let sourceContractListData = contractList.sort((a: any, b: any) => {
      if (!a.executionStartDate || !b.executionStartDate) {
        return 1;
      }
      const aStartData = moment(a.executionStartDate).unix();
      const bStartData = moment(b.executionStartDate).unix();
      return aStartData - bStartData;
    });
    // 保证保存前切换的产品信息不变 -- 查询条件没变的情况下记录table中的物料选择信息--只记录选中条目上一次的切换记录
    if (latestContractMulProductDoCreateItem && Object.keys(latestContractMulProductDoCreateItem).length > 0) {
      sourceContractListData.forEach((val: any) => {
        const doId1 = val.contractNo + cutBatchNoZero(val.batch);
        const doId2 =
          latestContractMulProductDoCreateItem.contractNo + cutBatchNoZero(latestContractMulProductDoCreateItem.batch);
        if (doId1 === doId2) {
          val.packageSpecification = latestContractMulProductDoCreateItem.packageSpecification;
          val.packageSpecificationCn = latestContractMulProductDoCreateItem.packageSpecificationCn;
          val.packingUnit = latestContractMulProductDoCreateItem.packingUnit;
          val.packingUnitDesc = latestContractMulProductDoCreateItem.packingUnitDesc;
          val.conversionFactor = latestContractMulProductDoCreateItem.conversionFactor;
          val.productName = latestContractMulProductDoCreateItem.productName;
          val.materialSalesText = latestContractMulProductDoCreateItem.materialSalesText;
          val.productCode = latestContractMulProductDoCreateItem.productCode;
          val.mixedBeanPulpFlag = latestContractMulProductDoCreateItem.mixedBeanPulpFlag;
        }
      });
    }

    const filterParams = {
      contractNo: searchParams.contractNo,
      batch: searchParams.batch,
      packageSpecification: searchParams.packageSpecification,
      shipmentMethod: searchParams.shipmentMethod
    };

    const contractListData = this.getFilterContractListData(sourceContractListData, filterParams);
    this.setState({
      sourceContractTableData: sourceContractListData,
      contractTableData: contractListData,
      fieldsDesc: fieldDesc,
      allFields: allFields,
      metadata: { ...metadata, resultCount: contractListData.length, totalCount: contractListData.length },
      current: updateType && updateType === "searchParamsChange" ? 1 : current,
      isChange: false
    });
  };

  // 重置清空待执行合同列表数据
  doResetExecutoryContractData = () => {
    this.setState({ contractTableData: [], metadata: { totalCount: 0 }, current: 1, isChange: false });
  };

  // 表格空状态提示
  customizeRenderEmpty = () => {
    const { searchValue } = this.state;
    const flag: boolean = searchValue.divisionCode && searchValue.customerCode && searchValue.orgCode;
    let noSearchInfo = "请选择卖方，买方，业务线及产品名称，以查看最新待执行合同！";
    let noResultInfo = "对不起，该买方暂无待执行合同/无法显示待执行合同！";
    if (this.props.userPermissions.CNExternalUsers) {
      noSearchInfo = "请选择卖方，业务线及产品名称，以查看最新待执行合同！";
      noResultInfo = "对不起，贵司暂无待执行合同/无法显示待执行合同！";
    }
    return (
      <div style={{ textAlign: "center", minHeight: 100, marginTop: 40, color: "#999" }}>
        <p>{flag ? noResultInfo : noSearchInfo}</p>
      </div>
    );
  };

  // 在切换物料或者切换合同选中前，需要先再跟用户确认是否切换，因为切换后会直接更新选中项，以及选中项中的数据
  // 等同于切换合同的操作
  onDoCheckProductName = (key: any, rows: any) => {
    const row: any = rows[0];
    if (row?.productList?.length > 1) {
      // 多物料提示确认后再选中
      const currentCheckSelectedProductInfo = row.productList.find((val: any) => val.productCode === row.productCode);
      this.setState({
        showCheckConfirmModel: true,
        checkSelectedProductInfo: { ...currentCheckSelectedProductInfo, contractNo: row.contractNo },
        mulProductsContractInfoTemp: { tableCheckedKeysTempArr: key, checkedRowTempArr: rows }
      });
    } else {
      // 单物料直接切换选中
      this.doTableRadioChange(key, rows);
    }
  };

  onDoCancelCheckConfirm = () => {
    const { tableRow, mulProductsContractInfoTemp } = this.state;
    if (
      tableRow.length > 0 &&
      mulProductsContractInfoTemp?.checkedRowTempArr.length > 0 &&
      tableRow?.[0].contractNo === mulProductsContractInfoTemp?.checkedRowTempArr?.[0].contractNo
    ) {
      // 如果取消的是当前选中项的物料切换，则需要直接清空选中数据
      this.setState(
        {
          showCheckConfirmModel: false,
          checkedRow: {},
          tableCheckedKeys: [],
          tableRow: []
        },
        () => {
          this.doSetContractCheckedRow({});
        }
      );
    } else {
      this.setState({ showCheckConfirmModel: false });
    }
  };

  // 多物料选择确认后的操作
  onMulProductsModalOk = (event: any) => {
    const { mulProductsContractInfoTemp } = this.state;
    this.setState({ showCheckConfirmModel: false });
    // 执行切换并进行开单数据更新时记录上一次的选中项 -- 用于当前取消
    this.setState({ latestContractMulProductDoCreateItem: mulProductsContractInfoTemp.checkedRowTempArr[0] });
    this.doTableRadioChange(
      mulProductsContractInfoTemp.tableCheckedKeysTempArr,
      mulProductsContractInfoTemp.checkedRowTempArr
    );
  };

  // 执行切换待执行合同列表行
  doTableRadioChange = async (key: any, rows: any) => {
    key = [key?.[0]?.replace("false", "true")];
    this.setState({ tableCheckedKeys: key });
    if (await this.changeData(rows, key)) {
      return;
    }
    this.setState({ tableRow: rows }, () => {
      this.doSetContractCheckedRow(rows[0]);
    });
  };

  // 物料切换后的产品名称并更新表格数据 -- 物料始终会被切换，同时check是否使用切换后的物料进行选中开单
  doChangeProductName = (currentValue: any, contractItemData: any) => {
    const { contractTableData, sourceContractTableData } = this.state;
    if (!contractItemData || !currentValue) {
      return;
    }
    // 查找出对应产品名称数组的数据
    const productList: any = contractItemData.productList.filter((product: any) => {
      let validateName = isHaveThisPermission("CNInternalUsers")
        ? product.productName
        : connectMaterialTextAndSpec(product.materialSalesText, product.packageSpecificationCn);
      if (product.businessLine === "包装油业务") {
        validateName = product.productName;
      }
      return validateName === currentValue;
    });
    if (!productList || productList.length <= 0) {
      return;
    }
    const product = productList[0];
    // 更新当前的合同条目中的选中产品信息
    const newContractItem: any = {
      ...contractItemData,
      packageSpecification: product.packageSpecification,
      packageSpecificationCn: product.packageSpecificationCn,
      packingUnit: product.packingUnit,
      packingUnitDesc: product.packingUnitDesc,
      productCode: product.productCode,
      conversionFactor: product.conversionFactor,
      productName: product.productName,
      materialSalesText: product.materialSalesText,
      mixedBeanPulpFlag: product.mixedBeanPulpFlag
    };
    // 使用变化后的物料数据进行确认操作
    const currentRowKey = this.onGetRowKey(newContractItem);
    // 如果是切换切换物料给出的确认提示，需要额外先将table数据进行更新
    const newTableData: any = [...contractTableData];
    const newSourceTableData: any = [...sourceContractTableData];
    const newItemIndex = newTableData.findIndex(
      (item: any) =>
        item.contractNo + cutBatchNoZero(item.batch) ===
        newContractItem.contractNo + cutBatchNoZero(newContractItem.batch)
    );
    const newItemSourceIndex = newSourceTableData.findIndex(
      (item: any) =>
        item.contractNo + cutBatchNoZero(item.batch) ===
        newContractItem.contractNo + cutBatchNoZero(newContractItem.batch)
    );

    if (newItemIndex !== -1) {
      newTableData[newItemIndex] = { ...newContractItem };
      newSourceTableData[newItemSourceIndex] = { ...newContractItem };
      this.setState({ contractTableData: newTableData, sourceContractTableData: newSourceTableData });
    }
    // 提示是否用心的物料开单
    this.onDoCheckProductName([currentRowKey], [newContractItem]);
  };

  // 判断是否为相同产品
  isSameproduct = (val: any, row: any) => {
    // 选中的物料与前面的多物料存在交集，则判断是相同产品
    return val.productList.some((product: any) => {
      return product.productCode === row.productCode;
    });
  };

  // 判断选中的合同前面是否有需要进行开单顺序检查的合同，返回true：表示存在需要进行开单顺序检查的合同，返回false：表示没有需要进行开单顺序检查的合同
  isContractEligible = (val: any, row: any) => {
    const valKey = val.contractNo + cutBatchNoZero(val.batch) + val.productCode;
    const rowKey = row.contractNo + cutBatchNoZero(row.batch) + row.productCode;
    return (
      val.buyer.trim() === row.buyer.trim() &&
      val.orgCode === row.orgCode &&
      this.isSameproduct(val, row) &&
      valKey !== rowKey &&
      val.businessLine === row.businessLine &&
      val.shipmentMethod === row.shipmentMethod &&
      val.actualRemainingDoQty &&
      ((val.businessDivision === "VA" || val.mixedBeanPulpFlag) ? val.deliveryLocationCode === row.deliveryLocationCode : true) &&
      val.unLocked
    );
  };

  // 检查业务线条件
  checkBusinessLineCondition = (businessLine: string, actualRemainingDoQty: number, mixedBeanPulpFlag: boolean): boolean => {
    if (businessLine === "基础精炼业务") {
      return actualRemainingDoQty >= 2;
    }

    if (businessLine === "谷物油籽业务" || mixedBeanPulpFlag) {
      return actualRemainingDoQty >= 4;
    }

    return false;
  };

  // 检查包装油业务条件
  checkPackageCondition = (businessLine: string, packageSpec: string, qty: number): boolean => {
    if (!["包装油业务", "增值油业务"].includes(businessLine)) {
      return false;
    }

    if (packageSpec !== "BULK") {
      return true;
    }

    return qty > 2;
  };

  // 第一轮开单顺序检查
  isContractEligibleForPriorityFirst = (val: any, row: any, contractExecutionSequenceControl: boolean): boolean => {
    // 判断是否为相同类型合同
    if (!this.isContractEligible(val, row)) {
      return false;
    }

    const currentProduct = row.productList.find((item: any) => item.productCode === row.productCode);

    // 合同或产品的强制按顺序执行标识为false，则给出普通顺序提示
    if (!contractExecutionSequenceControl || !currentProduct?.contractExecutionSequenceControl) {
      return true;
    }

    const businessCondition = this.checkBusinessLineCondition(val.businessLine, val.actualRemainingDoQty, val.mixedBeanPulpFlag);
    const dateCondition = daysBetween("", val.executionStartDate) >= 40;
    const packageCondition = this.checkPackageCondition(val.businessLine, val.packageSpecification, val.actualRemainingDoQty);
    // 发货地址不以9开头的合同，或发货地址以9开头且为杂粕的合同，需要进行合同强制执行检查
    const isMixedBeanPulp = !val.deliveryLocationCode?.startsWith("9") || (val.mixedBeanPulpFlag && val.deliveryLocationCode?.startsWith("9"));

    // 强制按顺序执行检查
    if (((businessCondition && dateCondition) || packageCondition) && isMixedBeanPulp) {
      // 需要强制执行的顺序提示
      const chanTipsTypeOptions: any = {
        "谷物油籽业务": "2",
        "谷物油籽贸易业务": "2",
        "基础精炼业务": "3",
        "包装油业务": "4",
        "增值油业务": "4"
      };
      this.setState({ changeTipsType: chanTipsTypeOptions[val.businessLine] });
      return true;
    }

    return false;
  };

  // 第二轮开单顺序检查
  isContractEligibleForPrioritySecond = (val: any, row: any, contractExecutionSequenceControl: boolean): boolean => {
    if (!this.isContractEligible(val, row)) {
      return false;
    }

    if (["包装油业务", "增值油业务"].includes(val.businessLine)) {
      return false;
    }

    const currentProduct = row.productList.find((item: any) => item.productCode === row.productCode);

    if (!contractExecutionSequenceControl || !currentProduct?.contractExecutionSequenceControl) {
      return true;
    }

    // 在第一轮未检查到的合同中进行开单顺序检查
    const isLowQty = (val.businessLine === "谷物油籽业务" || val.mixedBeanPulpFlag) && val.actualRemainingDoQty < 4;
    const isLowRefinedQty = val.businessLine === "基础精炼业务" && val.actualRemainingDoQty < 2;
    const isEarlyDate = daysBetween("", val.executionStartDate) < 40;
    const isNotMixedBeanPulp = !val.mixedBeanPulpFlag && val.deliveryLocationCode?.startsWith("9");

    return isLowQty || isLowRefinedQty || isEarlyDate || isNotMixedBeanPulp;
  };

  changeData = async (rows: any, key: any) => {
    const row = rows[0];
    if (["谷物油籽业务", "谷物油籽贸易业务", "基础精炼业务", "包装油业务", "增值油业务"].includes(row.businessLine)) {
      const contractExecutionSequenceControl = row.contractExecutionSequenceControl; // 获取合同强制按顺序执行标识
      const { sourceContractTableData } = this.state;
      const data: any = [...sourceContractTableData];
      const rowKey = row.contractNo + cutBatchNoZero(row.batch) + row.productCode;
      // 获取当前选中合同的index下标，用于后面获取选中合同前的需要进行开单顺序检查的合同
      const index = data.findIndex(
        (val: any) => val.contractNo + cutBatchNoZero(val.batch) + val.productCode === rowKey
      );
      const frontData = data.slice(0, index);
      // VA合同不需要普通顺序提示
      if (["包装油业务", "增值油业务"].includes(row.businessLine) && !contractExecutionSequenceControl) {
        return false;
      }
      let firstIndex = -1; // 选中数据之前有相同买方和产品名称的下标
      firstIndex = frontData.findIndex((val: any) => this.isContractEligibleForPriorityFirst(val, row, contractExecutionSequenceControl)); // 第一轮开单顺序检查
      if (firstIndex === -1) {
        firstIndex = frontData.findIndex((val: any) => this.isContractEligibleForPrioritySecond(val, row, contractExecutionSequenceControl)); // 第二轮开单顺序检查
      }
      if (firstIndex !== -1) {
        const keys =
          frontData[firstIndex].contractNo +
          cutBatchNoZero(frontData[firstIndex].batch) +
          frontData[firstIndex].productCode;
        const changeInfo = { key: [keys], data: [frontData[firstIndex]], index: firstIndex };
        const noChangeInfo = { key: key, data: rows, index: firstIndex };
        this.setState({ isChange: true, changeInfo: changeInfo, noChangeInfo: noChangeInfo });
        return true;
      }
    }
    return false;
  };

  // 同意按顺序选择
  agreeChange = () => {
    const { changeInfo, sourceContractTableData, metadata, contractTableData } = this.state;
    // 判断需要优先执行的待执行合同是否在当前显示的列表中，如果没有在列表中，则清空高级筛选项，并选中指定合同，如果在列表中，则直接选中指定合同
    const dataIndex = contractTableData.findIndex((val: any) => {
      return val.contractNo + cutBatchNoZero(val.batch) + val.productCode === changeInfo.key[0]
    });
    // 在当前列表中找到了需要优先执行的待执行合同的当前页码计算公式
    let current = Math.floor(dataIndex / 5) + 1;
    let contractTableDataTemp: any = contractTableData;
    let metadataTemp: any = metadata;
    if (dataIndex === -1) {
      contractTableDataTemp = sourceContractTableData;
      metadataTemp = {
        ...metadata,
        resultCount: sourceContractTableData.length,
        totalCount: sourceContractTableData.length
      };
      // 在当前列表中未找到需要优先执行的待执行合同的当前页码计算公式
      current = Math.floor(changeInfo.index / 5) + 1;
      this.props?.resetAdvanceSearch(); // 清空高级筛选项
    }
    this.setState(
      { tableCheckedKeys: changeInfo.key, tableRow: changeInfo.data, current: current, metadata: metadataTemp, contractTableData: contractTableDataTemp, isChange: false, changeTipsType: "1" },
      () => {
        this.doSetContractCheckedRow(changeInfo.data?.[0]);
      }
    );
  };

  // 不同意按顺序选中
  disagreeChange = () => {
    const { noChangeInfo } = this.state;
    this.setState({ tableCheckedKeys: noChangeInfo.key, tableRow: noChangeInfo.data, isChange: false, changeTipsType: "1" }, () => {
      this.doSetContractCheckedRow(noChangeInfo.data?.[0]);
    });
  };

  pageChange = (page: number) => {
    this.setState({ current: page });
  };

  onGetRowKey = (record: any) => {
    return record.contractNo + cutBatchNoZero(record.batch) + record.productCode;
  };

  // 获取字段设置后的表头
  getNewColumns = (columns: any) => {
    const { AllTableColumns } = this.state;
    const { userPermissions } = this.props;
    let newColumns = columns.map((item: any) => {
      return AllTableColumns.find((val: any) => val.key === item);
    });
    if (userPermissions.CNInternalUsers) {
      // 内部用户拥有解锁的操作权限
      newColumns = this.addOperateColumns(newColumns);
    }
    this.setState({ tableColumns: newColumns });
  };

  // 获取待执行合同字段设置后的columns
  getColumns = (data: any, dataSource: any, setVisibleFun: any) => {
    const { allFields } = this.state;
    let temp = data.map((item: any) => {
      if (dataSource.length > 0) {
        return dataSource.find((val: any) => val.value === item);
      }
      return allFields.find((val: any) => val.value === item);
    });
    temp = temp.filter((val: any) => val);
    this.getNewColumns(data);
    saveContractFieldsOrder({
      type: "sapUnexecutedList",
      fieldDesc: JSON.stringify(temp)
    })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        }
      })
      .catch((exception: any) => {
        console.log(`saveContractFieldsOrder Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      })
      .finally(() => {
        setVisibleFun(false);
        this.doGetCIDOSContracts();
      });
  };

  // 待执行合同列表锁定解锁
  setLock = (row: any, flag: string) => (event?: any) => {
    const { contractNo, batch } = row;
    setLockUnlockContract({ contractNo, batch, lockType: flag })
      .then((res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else {
          this.doGetCIDOSContracts();
        }
      })
      .catch((exception: any) => {
        console.log(`setLockUnlockContract Failure: ${exception}`);
        message.error("设置失败，请稍后重试。");
      });
  };

  public render() {
    const { userPermissions } = this.props;
    const {
      tableCheckedKeys,
      contractTableData,
      metadata,
      loading,
      fieldsDesc,
      allFields,
      tableColumns,
      current,
      showCheckConfirmModel,
      checkSelectedProductInfo,
      isChange,
      changeInfo,
      changeTipsType
    } = this.state;

    return (
      <Card
        style={{ marginTop: "12px", boxShadow: "0 5px 9px #dedbdb" }}
        className="cards"
        title="待执行合同"
        extra={
          <>
            <span className="total">共 {contractTableData.length > 0 ? metadata.totalCount : 0} 条</span>
            {contractTableData.length > 0 && (
              <FieldSetting fieldDesc={fieldsDesc} allFields={allFields} getColumns={this.getColumns} />
            )}
          </>
        }
      >
        <div className="change-contract-modal-table">
          <ConfigProvider renderEmpty={this.customizeRenderEmpty}>
            <Table
              data-testid="test-contract-list"
              rowSelection={{
                type: "radio",
                fixed: true,
                selectedRowKeys: tableCheckedKeys,
                onChange: this.onDoCheckProductName,
                getCheckboxProps: (record: any) => {
                  return {
                    disabled: userPermissions.CNExternalUsers
                      ? !record.actualRemainingDoQty || !record.unLocked
                      : !record.actualRemainingDoQty
                  };
                }
              }}
              size="small"
              rowKey={this.onGetRowKey}
              columns={[...tableColumns]}
              dataSource={contractTableData}
              loading={loading}
              scroll={{ x: "max-content" }}
              pagination={{
                showQuickJumper: true,
                pageSize: 5,
                showSizeChanger: false,
                current: current,
                size: "default",
                onChange: this.pageChange
              }}
            />
          </ConfigProvider>
          {isChange && (
            <ChangeContractModal data={changeInfo?.data} changeTipsType={changeTipsType} onOk={this.agreeChange} onCancel={this.disagreeChange} />
          )}
          {/* 产品名称可选，选中提示框 */}
          <Modal
            title="提示！"
            visible={showCheckConfirmModel}
            centered
            maskClosable={false}
            onCancel={this.onDoCancelCheckConfirm}
            onOk={this.onMulProductsModalOk}
          >
            {`本合同（${checkSelectedProductInfo.contractNo}）有多个物料可选，是否选择物料“${getProductNameInitValue(
              checkSelectedProductInfo
            )}”进行开单?`}
          </Modal>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state: any) => ({
  userPermissions: state.userPermissions,
  userDetails: state.userDetails,
  switcher: state.switcher
});

export default connect(mapStateToProps, null)(ContractList);
