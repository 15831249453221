import { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
//页面引入
import Login from "@/pages/login/login";
import MyLayout from "@/pages/layout/layout";
import Error from "@/pages/error/error";
import TermsConditions from "@/pages/termsConditions";
import PersonalInfoCrossBorderNotice from "@/pages/crossBorderTransferNotice";
import QuestionnaireSurvey from "@/pages/questionnaireSurvey";

// react-router 4.0 以后不再推荐将所有路由规则放在同一个地方集中式路由，
// 子路由应该由父组件动态配置，组件在哪里匹配就在哪里渲染，更加灵活
export default class RouteInitConfig extends Component<{}, {}> {
  public render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/login" />} />
          <Route path="/login" component={Login} />
          <Route path="/main" component={MyLayout} />
          <Route path="/termsconditions" component={TermsConditions} />
          <Route path="/crossbordertransfernotice" component={PersonalInfoCrossBorderNotice}/>
          <Route path="/questionnairesurvey/:key" component={QuestionnaireSurvey}/>
          <Route component={Error} />
        </Switch>
      </BrowserRouter>
    );
  }
}
