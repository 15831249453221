import { SurveyData } from './types';

export const soyMealSurvey: SurveyData = {
  "clientCompanyName": "",
  "respondentName": "",
  "respondentContact": "",
  "companyType": "",
  "respondentPosition": "",
  "otherPartnerCompany": "",
  "surveyItems": [
    {
      "item": "绩效指标",
      "questions": [
        {
          "question": "3、您有多大可能向同行或同事推荐这些公司？",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ]
        },
        {
          "question": "4、总体来说，您对这些公司的满意程度如何？",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ]
        },
        {
          "question": "5、考虑到所提供的产品和服务，您如何评价这些公司为您的业务提供的整体价值？",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ]
        },
        {
          "question": "6、您在多大程度上认同这些公司是您的首选合作伙伴？",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ]
        },
        {
          "question": "7、和这些公司合作让您感受如何？",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ]
        },
        {
          "question": "8、和这些公司合作有多简便？",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ]
        }
      ]
    },
    {
      "item": "整体感受",
      "questions": [
        {
          "question": "9、这是一家可以信任的公司吗？",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ]
        },
        {
          "question": "10、该公司雇佣了一流的人才吗？",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ]
        },
        {
          "question": "11、该公司以客户利益为首吗？",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ]
        }
      ]
    },
    {
      "item": "可持续发展",
      "questions": [
        {
          "question": "12、该公司展现了对环境和社会可持续发展的领导力吗？",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ]
        },
        {
          "question": "13、您目前是否正在参加嘉吉的可持续发展项目？",
          "type": "boolean",
          "required": true,
          "result": "否"
        },
        {
          "question": "14、您是否对嘉吉的可持续发展项目有兴趣？",
          "type": "boolean",
          "required": true,
          "result": "否"
        }
      ]
    },
    {
      "item": "创新",
      "questions": [
        {
          "question": "15、当您在寻求创新解决方案时，是否是您的首选供应商？",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ]
        }
      ]
    },
    {
      "item": "销售人员",
      "questions": [
        {
          "question": "16、能有效地解决您的业务问题吗？",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ]
        },
        {
          "question": "17、能提供正向的体验吗？",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ]
        },
        {
          "question": "18、能得到响应吗？",
          "type": "rating",
          "required": true,
          "companies": [  
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ]
        },
        {
          "question": "19、是积极主动的吗？",
          "type": "rating",
          "required": true,
          "companies": [  
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ]
        },
        {
          "question": "20、能提供相关的知识、见解和经验吗？",
          "type": "rating",
          "required": true,
          "companies": [  
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ]
        },
        {
          "question": "21、是值得信任的吗？",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ]
        }
      ],
      "comments": {
        "label": "对于嘉吉销售人员，您的意见或建议：",
        "value": ""
      }
    },
    {
      "item": "产品质量和服务水平",
      "questions": [
        {
          "question": "22、豆粕质量：水分、蛋白等质量表现",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ],
          "comments": {
            "label": "关于豆粕质量，您的意见或建议：",
            "value": ""
          }
        },
        {
          "question": "23、销售服务：基差转月/点价回抛/CRM增值服务等",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ],
          "comments": {
            "label": "关于销售服务，您的意见或建议：",
            "value": ""
          }
        },
        {
          "question": "24、财务服务：现金入账/清账时效性等",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ],
          "comments": {
            "label": "关于财务服务，您的意见或建议：",
            "value": ""
          }
        },
        {
          "question": "25、发货计划：计划安排、自提可预约时间窗发布合理性",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null}
          ],
          "comments": {
            "label": "关于发货计划，您的意见或建议：",
            "value": ""
          }
        },
        {
          "question": "26、配送服务：及时性及运费、损耗的合理性",
          "type": "rating",
          "required": false,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null} 
          ],
          "comments": {
            "label": "关于配送服务，您的意见或建议：",
            "value": ""
          }
        },
        {
          "question": "27、关于工厂提货的体验：保安服务态度/装货现场人员服务态度等",
          "type": "rating",
          "required": false,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null} 
          ],
          "comments": {
            "label": "关于工厂提货，您的意见或建议：",
            "value": ""
          }
        },
        {
          "question": "28、售后服务：服务态度/投诉处理的及时性等",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null} 
          ],
          "comments": {
            "label": "关于售后服务，您的意见或建议：",
            "value": ""
          }
        },
        {
          "question": "29、目前我司提供的产品（豆粕/菜粕/磷脂/原油等）和服务之外，您还有其它哪些需求吗：",
          "type": "input",
          "required": false,
          "result": ""
        }
      ]
    },
    {
      "item": "数字化客户端",
      "questions": [
        {
          "question": "30、数字化客户端-余额/欠款/对账单查询",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null} 
          ]
        },
        {
          "question": "31、数字化-合同明细查询",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null} 
          ]
        },
        {
          "question": "32、数字化-自主开单",
          "type": "rating",
          "required": true,
          "companies": [
            {"company": "嘉吉", "score": null},
            {"company": "", "score": null} 
          ]
        },
        {
          "question": "33、您认为在与嘉吉的业务合作中，嘉吉的哪些方面做得最好？",
          "type": "input",
          "required": false,
          "result": ""
        },
        {
          "question": "34、您希望嘉吉在哪些方面能做得更好？",
          "type": "input",
          "required": false,
          "result": ""
        }
      ]
    }
  ]
};
