import { useEffect, useState, useMemo, useCallback } from 'react';
import { Skeleton, Modal, Button } from 'antd';
import { soyMealSurvey } from '@/utils/surveyInitData/soyMealSurvey';
import { oilSurveyFSBR } from '@/utils/surveyInitData/oilSurveyFSBR';
import { oilSurveyKAFI } from '@/utils/surveyInitData/oilSurveyKAFI';
import Survey from './components/survey';
import { FormData, MetaData, SurveyData } from '@/utils/surveyInitData/types';
import logo from '@/assets/images/cargill-logo.png';
import { decryptApiKey } from '@/utils/apiKey';
import './index.scss';

interface SurveyInfo {
  title: string;
  temKey: string;
  state: string;
  recordVersion: string;
}

const QuestionnaireSurvey: React.FC = () => {
  const [metaData, setMetaData] = useState<MetaData | null>(null);
  const [initialData, setInitialData] = useState<SurveyData | null>(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const isPreviewMode = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('preview') === 'true';
  }, []);

  const handlePrint = useCallback(() => {
    window.print();
  }, []);

  const handleSubmit = async (formData: FormData): Promise<void> => {
    if (isPreviewMode) {
      Modal.info({
        title: '提示',
        content: '预览模式下不能提交问卷',
        okText: '确定'
      });
      return;
    }
    if (isSubmitted) {
      Modal.error({
        title: '提交问卷失败',
        content: '该问卷已提交，请勿重复提交！',
        okText: '确定'
      });
      return;
    }

    setSubmitLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/notificationsap/survey/saveSurveyAnswer?countryCode=CN`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "ApiKey": decryptApiKey(process.env.REACT_APP_SURVEY_REQUEST_HASH || '')
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('提交失败');
      }

      setIsSubmitted(true);
      Modal.success({
        title: '提交问卷成功',
        content: (
          <>
            <p>问卷提交成功！感谢您的参与！</p>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', marginTop: '24px' }}>
              <Button type="primary" onClick={() => Modal.destroyAll()}>
                确定
              </Button>
              <Button onClick={handlePrint} type="default">
                打印问卷
              </Button>
            </div>
          </>
        ),
        wrapClassName: 'print-modal',
        okButtonProps: { style: { display: 'none' } },
        cancelButtonProps: { style: { display: 'none' } },
        getContainer: () => document.querySelector('.survey') || document.body,
      });
    } catch (error) {
      console.error('提交失败:', error);
      Modal.error({
        title: '提交问卷失败',
        content: '提交失败，请稍后重试！',
        okText: '确定'
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  // 获取问卷模板数据
  const getTemplateData = (temKey: string): SurveyData | null => {
    switch (temKey) {
      case '豆粕调查表':
        return soyMealSurvey;
      case '油调查表-FS&BR':
        return oilSurveyFSBR;
      case '油调查表-KA&FI':
        return oilSurveyKAFI;
      default:
        return null;
    }
  };

  useEffect(() => {
    document.title = "问卷调查";
    const getSurveyInfo = async () => {
      try {
        // 从 URL 中获取 surveyKey
        const pathSegments = window.location.pathname.split('/');
        const surveyKey = pathSegments[pathSegments.length - 1];

        if (!surveyKey) {
          throw new Error('未找到问卷标识');
        }

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/notificationsap/survey/getInfoByKey?surveyKey=${surveyKey}&countryCode=CN`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            "ApiKey": decryptApiKey(process.env.REACT_APP_SURVEY_REQUEST_HASH || '')
          },
        });
        if (!response.ok) {
          throw new Error('获取问卷信息失败');
        }

        const result = await response.json();
        const surveyInfo: SurveyInfo = result.data;

        // 设置问卷元数据
        setMetaData({
          surveyKey: surveyKey,
          title: surveyInfo.title,
          type: surveyInfo.temKey,
          state: surveyInfo.state,
          logo: logo,
        });

        // 设置问卷初始数据
        setInitialData(getTemplateData(surveyInfo.temKey));
      } catch (error) {
        console.error('获取问卷信息失败:', error);
        Modal.error({
          title: '错误',
          content: '获取问卷信息失败，请稍后重试！',
          okText: '确定'
        });
      } finally {
        setLoading(false);
      }
    };
    getSurveyInfo();
  }, []);

  if (loading) {
    return (
      <div style={{ padding: '24px' }}>
        <Skeleton active />
      </div>
    );
  }

  // 如果问卷状态为关闭，显示失效提示
  if (metaData?.state === 'close' && !isPreviewMode) {
    return (
      <div className="questionnaire-survey">
        <div className="survey-expired">
          <img src={logo} alt="嘉吉logo" className="expired-logo" />
          <div className="expired-message">
            <h2>此问卷已失效</h2>
            <p>感谢您的关注，但该问卷已不再接受填写。</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="questionnaire-survey">
      {isPreviewMode && (
        <div className="preview-mode-banner">
          预览模式
        </div>
      )}
      {metaData && initialData && (
        <Survey
          metaData={metaData}
          initialData={initialData}
          onSubmit={handleSubmit}
          submitLoading={submitLoading}
          isSubmitted={isSubmitted}
        />
      )}
    </div>
  );
};

export default QuestionnaireSurvey; 